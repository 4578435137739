import * as React from 'react'
import { useEffect, useRef, useState } from "react"
import { isMobileOnly } from "react-device-detect"
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { section1, textHeader } from "../../styles/story.module.scss"
import { StaticImage } from "gatsby-plugin-image"


const StoryPage = ({ location }) => {
  const [width, setWidth] = useState(0)
  const [offset, setOffset] = useState(0)
  const [firstOffset, setFirstOffset] = useState(1470)
  const [innovationOffset, setInnovationOffset] = useState(2684)
  const [secondOffset, setSecondOffset] = useState(100)
  const [ourTeamOffset, setOurTeamOffset] = useState(100)

  const firstRef = useRef();
  const secondRef = useRef();
  const innovationRef = useRef();
  const ourTeamRef = useRef();

  useEffect(() => {
    const onScroll = () => {
      setWidth(window.innerWidth)
      setOffset(window.pageYOffset)

      setFirstOffset(firstRef.current.offsetTop)
      setInnovationOffset(innovationRef.current.offsetTop)
      setSecondOffset(secondRef.current.offsetTop)
      setOurTeamOffset(ourTeamRef.current.offsetTop)
    }
    window.removeEventListener("scroll", onScroll)
    window.addEventListener("scroll", onScroll, { passive: true })
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  const firstClassEffect = () => {
    // console.log(offset)
    // console.log(firstOffset)
    let className = '';

    if (width >= 768) {
      if (width < 1536  && firstOffset - offset < 0 && offset > 1340) {
        className = 'fixed__innovation';
      } else {
        if (firstOffset - offset < 0 && offset > 1620) {
          className = 'fixed__innovation';
        }
      }
    } else {
      if (firstOffset - offset < 0 && offset > 710) {
        className = 'fixed__innovation';
      }
    }

    return className;
  };

  const secondClassEffect = () => {
    // console.log(offset)
    // console.log(secondOffset)
    // console.log(width)
    let className = '';
    if (width >= 768) {
      if (width < 1536  && secondOffset - offset < 0 && offset > 3490) {
        className = 'fixed__our_team';
      } else {
        if (secondOffset - offset < 0 && offset > 3890) {
          className = 'fixed__our_team';
        }
      }
    } else {
      if (secondOffset - offset < -20 && offset > 2990) {
        className = 'fixed__our_team';
      }
    }

    return className;
  };


  return (
    <Layout pageTitle="Story" location={location}>
      <div className="mx-auto">
        <section className="pt-20 pb-10 md:py-0 2xl:py-0 md:mb-10">
          <div className="w-hidden">
            <StaticImage
              alt="australia-108-melbourne"
              src="../../images/story/Hero-Careers.png"
            />
          </div>
          <div className="mb-hidden">
            <StaticImage
              className="w-full md:max-h-[700px] 2xl:max-h-[900px]"
              alt="australia-108-melbourne"
              src="../../images/story/Hero-Careers.png"
            />
          </div>
        </section>
        <section className={section1}>
          <p className="font-bold text-[30px] md:text-[40px] xl:text-[60px] 2xl:text-[90px] justify-center text-center uppercase pink-linear head-text">A Resident-first, fully integrated building management platform – <br/> “the fifth wall”.</p>
        </section>
      </div>
      <div
        ref={firstRef}
        className={firstClassEffect()}
        style={{opacity: innovationOffset - offset > 0 ? 1 : 0}}
      >
        <section className="flex py-[140px] md:py-[190px] justify-center items-center">
          <div className="container mx-auto md:px-4 text-content text-center text-white">
            <h1 className="font-bold text-[24px] md:text-[32px] py-3 head-text">Genesis of WE Wumbo</h1>
            <p className="text-[14px] line-[17px] font-bold px-[16px] md:px-[0]">
              is from our own experiences and pain points in an industry we intimately know and love.<br/>
              <u>Excite & Connect</u> are two sentiments we strive to achieve in everything we do.
            </p>
          </div>
        </section>
      </div>
      <div className="fixed__spacer">
        <div className="fixed__spacer-inner" style={{height: isMobileOnly ? '406px' : '300px'}}></div>
      </div>
      <div className="section-relative" ref={innovationRef}>
        <section className="container mx-auto px-4 md:mx-auto">
          <div className="flex flex-col md:flex-row md:h-[900px] justify-center md:px-[80px] xl:px-[180px] 2xl:px-auto">
            <div className="flex-auto mx-auto w-[170px] md:w-5/12 md:my-auto">
              <div className="max-w-[300px] md:max-w-none mx-auto">
                <StaticImage
                  alt="australia-108-melbourne"
                  src="../../images/story/Web_45_Innovation.png"
                />
              </div>
            </div>
            <div className="flex-auto md:w-7/12 text-white my-[40px] md:my-auto md:ml-[50px] xl:ml-[100px] text-center md:text-left">
              <div className="font-bold text-[30px] md:text-[40px] xl:text-[60px] 2xl:text-[90px] leading-none uppercase blue-linear md:py-3 head-text">Innovation is <br/> our goal. <br/>
              Disruption is <br/> our by-product.</div>
              <div className="pt-[22px] pt-10 text-sm md:text-base">
              By leveraging the power of technology and equipping <b className="bold-text text-sm md:text-base"> the front line with the "right information" at the "right moment” </b> we are reimagining the built environment to meet the needs of today's urban live-work-stay-play culture.
Our cross functional team’s <b className="bold-text text-sm md:text-base"> vision centers upon operational efficiencies, resident experiences, community connectivity, exchange of goods, services and social currency, products and solving current pain points with state-of-the-art technology, cutting edge data capabilities, and strategic partnerships.</b>
              </div>
            </div>
          </div>
        </section>
        <div className="container px-4 md:mx-auto my-20 md:my-auto">
          <section className="flex flex-col md:h-[900px] justify-center text-center text-white md:px-[80px] xl:px-[180px] 2xl:px-auto">
            <p className="font-bold text-[30px] leading-[45px] md:text-[90px] md:leading-[90px] leading-none uppercase pink-linear py-0 head-text">Our Winning Formula.</p>
            <div className="md:mx-auto py-[14px] md:py-10">
            <div className="font-light text-sm md:text-base">
              Put simply, a Smart Building, Smart Community achieves operational efficiencies and community connectivity - meaning people love their living environment which attracts prospective buyers and tenants to the building. Ultimately, a smart building achieves capital growth for both property rentals and resales!
            </div>
            </div>
            <div className="flex flex-col md:flex-row md:pt-10 justify-center text-center md:self-center">
              <div className="my-5 md:py-0 px-0 md:px-20">
                <StaticImage
                  alt="australia-108-melbourne"
                  src="../../images/story/Web_46_Operational_Efficiency.png"
                />
                <div className="md:text-[16px] pt-[16px] md:pt-[40px] font-bold">Operational Efficiency</div>
              </div>
              <div className="mx-auto my-5 md:my-auto w-[20px] h-[20px] md:w-[45px] md:h-[45px] md:relative md:top-[-42px]">
                <StaticImage
                  alt="australia-108-melbourne"
                  src="../../images/story/+.png"
                />
                <div className="md:text-[16px] pt-[16px]">&nbsp;</div>
              </div>
              <div className="my-5 md:py-0 px-0 md:px-20">
                <StaticImage
                  alt="australia-108-melbourne"
                  src="../../images/story/Web_47_Community_Connectivity.png"
                />
                <div className="md:text-[16px] pt-[16px] md:pt-[40px] font-bold">Community Connectivity</div>
              </div>
              <div className="mx-auto my-5 md:my-auto w-[20px] h-[20px] md:w-[45px] md:h-[45px] md:relative md:top-[-42px]">
                <StaticImage
                  alt="australia-108-melbourne"
                  src="../../images/story/=.png"
                />
                <div className="md:text-[16px] pt-[16px] relative top-[-42px]">&nbsp;</div>
              </div>
              <div className="my-5 md:py-0 px-0 md:px-20">
                <StaticImage
                  alt="australia-108-melbourne"
                  src="../../images/story/Web_48_Capital_Growth_Resident_Retention.png"
                />
                <div className="md:text-[16px] pt-[16px] md:pt-[40px] font-bold">Capital Growth & <br/> Resident Retention</div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div
        ref={secondRef}
        className={secondClassEffect()}
        style={{opacity: ourTeamOffset - offset > 0 ? 1 : 0}}
      >
        <section className="text-content text-center text-white">
          <div className="relative">
            <div className="mb-hidden">
              <StaticImage
                alt="australia-108-melbourne"
                className="max-h-[450px] w-full"
                src="../../images/story/Web_49_Michael.png"
              />
            </div>
            <div className="w-hidden">
              <StaticImage
                alt="australia-108-melbourne"
                src="../../images/story/Mobile_49_Michael.png"
              />
            </div>
            <div className="md:absolute md:inset-0 mt-[40px] md:my-auto md:mx-[300px] md:overflow-auto w-full h-fit">
              <div className="overflow-y-hidden">
                <h1 className="text-[24px] md:text-[32px] uppercase font-bold head-text">Michael Lee</h1>
                <p className="text-[12px] md:text-[16px] uppercase font-bold head-text">Founder & CEO</p>
              </div>
            </div>
          </div>
        </section>
        <section className="flex mt-5 md:my-[64px] 2xl:my-[160px] justify-center items-center mb-hidden">
          <div className="container mx-auto text-center text-white">
            <div className="px-4 md:px-[80px] xl:px-[180px] 2xl:px-auto">
              <p className="font-light mx-auto text-[14px] leading-[17px] md:text-[16px] md:leading-[19px]">
                Michael has more than 25 years experience in the real estate industry in Australia and Vietnam including master-planned communities, volume homes, mixed-use developments, residential apartments and more recently hotels. He founded WE Wumbo to solve the industry’s complex pain points and understands prop tech’s significance in placemaking to enrich the lives and experiences of the people in it.
              </p>
              <p className="pt-4 font-light mx-auto text-[14px] leading-[17px] md:text-[16px] md:leading-[19px]">
                "The ubiquity of internet and smart devices is ever changing the way we live, work, play and interact. But it’s not so much the technology, rather what we are capable of doing with it, and to what extent we allow logic and common sense to guide adoption."
              </p>
            </div>
          </div>
        </section>
      </div>
      <div className="fixed__spacer">
        <div className="fixed__spacer-inner" style={{height: isMobileOnly ? '870px' : '900px'}}></div>
      </div>
      <div className="section-relative" ref={ourTeamRef}>
        <section className="flex md:h-[406px] 2xl:h-[450px] mt-5 mb-20 py-10 justify-center items-center w-hidden">
          <div className="container mx-auto text-center text-white">
            <div className="px-4 md:px-[80px] xl:px-[180px] 2xl:px-auto">
              <p className="font-light mx-auto text-[14px] leading-[17px] md:text-[16px] md:leading-[19px]">
                Michael has more than 25 years experience in the real estate industry in Australia and Vietnam including master-planned communities, volume homes, mixed-use developments, residential apartments and more recently hotels. He founded WE Wumbo to solve the industry’s complex pain points and understands prop tech’s significance in placemaking to enrich the lives and experiences of the people in it.
              </p>
              <p className="pt-4 font-light mx-auto text-[14px] leading-[17px] md:text-[16px] md:leading-[19px]">
                "The ubiquity of internet and smart devices is ever changing the way we live, work, play and interact. But it’s not so much the technology, rather what we are capable of doing with it, and to what extent we allow logic and common sense to guide adoption."
              </p>
            </div>
          </div>
        </section>
        <section className="text-content text-center text-white pt-[80px] md:py-[180px]">
          <div className="container mx-auto px-4 md:px-[80px] xl:px-[180px] 2xl:px-auto">
            <h1 className="font-bold text-[30px] md:leading-[30px] md:text-[90px] md:leading-[90px] uppercase blue-linear head-text">Our Team.</h1>
            <p className="font-light md:mx-auto mt-[14px] md:mt-[24px] text-[14px] leading-[17px] md:text-[16px] md:leading-[19px]">Our success to date, is testament of our passionate and committed team determined to make a difference, shared goals and most importantly make people around them better.
            </p>
            <div className="overflow-x-auto md:overflow-x-visible md:max-w-[1080px] md:mx-auto">
              <div className="flex flex-nowrap md:grid md:grid-cols-4 gap-4 md:justify-center my-10 md:my-10">
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="australia-108-melbourne"
                    src="../../images/story/Web_52_Our_Team.png"
                  />
                  <p className="font-bold text-[16px] leading-[16px] md:text-[20px] md:leading-[20px] uppercase pt-4 head-text">kim</p>
                  <p className="text-[10px] leading-[10px] md:text-[16px] md:leading-[16px] pt-1 md:pt-2">Product Owner</p>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="australia-108-melbourne"
                    src="../../images/story/Web_53_Our_Team.png"
                  />
                  <p className="font-bold text-[16px] leading-[16px] md:text-[20px] md:leading-[20px] uppercase pt-4 head-text">Daniel</p>
                  <p className="text-[10px] leading-[10px] md:text-[16px] md:leading-[16px] pt-1 md:pt-2">New Business & Innovation</p>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="australia-108-melbourne"
                    src="../../images/story/Web_55_Our_Team.png"
                  />
                  <p className="font-bold text-[16px] leading-[16px] md:text-[20px] md:leading-[20px] uppercase pt-4 head-text">Elly Choi</p>
                  <p className="text-[10px] leading-[10px] md:text-[16px] md:leading-[16px] pt-1 md:pt-2">UI/UX Designer</p>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="australia-108-melbourne"
                    src="../../images/story/dang.png"
                  />
                  <p className="text-[16px] leading-[16px] md:text-[20px] md:leading-[20px] uppercase pt-4 head-text">Dang</p>
                  <p className="text-[10px] leading-[10px] md:text-[16px] md:leading-[16px] pt-1 md:pt-2">DevOps Engineer</p>
                </div>
              </div>
              <div className="flex flex-nowrap md:grid md:grid-cols-4 gap-4 md:justify-center my-10 md:my-10">
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="australia-108-melbourne"
                    src="../../images/story/Web_56_Our_Team.png"
                  />
                  <p className="font-bold text-[16px] leading-[16px] md:text-[20px] md:leading-[20px] uppercase pt-4 head-text">Liam</p>
                  <p className="text-[10px] leading-[10px] md:text-[16px] md:leading-[16px] pt-1 md:pt-2">Onboarding Specialist</p>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="australia-108-melbourne"
                    src="../../images/story/Web_54_Our_Team.png"
                  />
                  <p className="font-bold text-[16px] leading-[16px] md:text-[20px] md:leading-[20px] uppercase pt-4 head-text">Vinita</p>
                  <p className="text-[10px] leading-[10px] md:text-[16px] md:leading-[16px] pt-1 md:pt-2">Business Analyst</p>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="australia-108-melbourne"
                    src="../../images/story/cong.png"
                  />
                  <p className="font-bold text-[16px] leading-[16px] md:text-[20px] md:leading-[20px] uppercase pt-4 head-text">Cong</p>
                  <p className="text-[10px] leading-[10px] md:text-[16px] md:leading-[16px] pt-1 md:pt-2">Web Developer</p>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="australia-108-melbourne"
                    src="../../images/story/vu.png"
                  />
                  <p className="font-bold text-[16px] leading-[16px] md:text-[20px] md:leading-[20px] uppercase pt-4 head-text">Vu</p>
                  <p className="text-[10px] leading-[10px] md:text-[16px] md:leading-[16px] pt-1 md:pt-2">Mobile Developer</p>
                </div>
              </div>
              <div className="flex flex-nowrap md:grid md:grid-cols-4 gap-4 md:justify-center my-10 md:my-10">
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="australia-108-melbourne"
                    src="../../images/story/Team-8.png"
                  />
                  <p className="font-bold text-[16px] leading-[16px] md:text-[20px] md:leading-[20px] uppercase pt-4 head-text">Mark</p>
                  <p className="text-[10px] leading-[10px] md:text-[16px] md:leading-[16px] pt-1 md:pt-2">Chief Strategy Officer</p>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="australia-108-melbourne"
                    src="../../images/story/Web_50_Our_Team.png"
                  />
                  <p className="font-bold text-[16px] leading-[16px] md:text-[20px] md:leading-[20px] uppercase pt-4 head-text">Anje</p>
                  <p className="text-[10px] leading-[10px] md:text-[16px] md:leading-[16px] pt-1 md:pt-2">Chief Operating Officer</p>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="australia-108-melbourne"
                    src="../../images/story/Team-13.png"
                  />
                  <p className="font-bold text-[16px] leading-[16px] md:text-[20px] md:leading-[20px] uppercase pt-4 head-text">Chase</p>
                  <p className="text-[10px] leading-[10px] md:text-[16px] md:leading-[16px] pt-1 md:pt-2">Head of Culture</p>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="australia-108-melbourne"
                    src="../../images/story/Team_11.png"
                  />
                  <p className="font-bold text-[16px] leading-[16px] md:text-[20px] md:leading-[20px] uppercase pt-4 head-text">kobe</p>
                  <p className="text-[10px] leading-[10px] md:text-[16px] md:leading-[16px] pt-1 md:pt-2">Wumbologist</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="text-content text-white my-10 md:my-[180px]">
          <div className="container mx-auto w-full md:w-2/5">
            <h1 className={textHeader}>WE WANT YOU IF...</h1>
            <div className="my-10 mx-4">
              <div className="border-b">
                <div className="flex justify-between pb-4 md:pb-5">
                  <div className="max-w-[312px] md:max-w-[512px]">
                    <p className="text-sm md:text-base">You bounce out of bed every morning, bursting with energy.</p>
                  </div>
                  <div className="my-auto">
                    <div className="float-right">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                           stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-b">
                <div className="flex justify-between py-4 md:py-5">
                  <div className="max-w-[315px] md:max-w-[512px]">
                    <p className="text-sm md:text-base">You get things done and your glass is half full, always.</p>
                  </div>
                  <div className="my-auto">
                    <div className="float-right">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                           stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-b">
                <div className="flex justify-between py-4 md:py-5">
                  <div className="basis-11/12">
                    <p className="text-sm md:text-base">You like detail.</p>
                  </div>
                  <div className="my-auto">
                    <div className="float-right">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                           stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-b">
                <div className="flex justify-between py-4 md:py-5">
                  <div className="max-w-[315px] md:max-w-[512px]">
                    <p className="text-sm md:text-base">You’re incurably curious and ambitious — but not precious.</p>
                  </div>
                  <div className="my-auto">
                    <div className="float-right">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                           stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-b">
                <div className="flex justify-between py-4 md:py-5">
                  <div className="max-w-[315px] md:max-w-[512px]">
                    <p className="text-sm md:text-base">You’re a spirited adventurer, experimenter, a master of many skills and trades.</p>
                  </div>
                  <div className="my-auto">
                    <div className="float-right">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                           stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <p className="mb-4 md:mb-6 text-sm md:text-base">If this sounds like you, please get in touch…</p>
              <a className="inline-block bg-blue rounded text-sm px-10 py-[12px] py-[11px] text-[14px] leading-[19px] font-bold" href="mailto:info@wewumbo.io">Contact Us</a>
            </div>
          </div>
        </section>
        <section className="text-content text-white mt-20 md:mt-10 mb-[80px] md:mb-[180px]">
          <div className="container mx-auto w-full md:w-2/5">
            <h1 className={textHeader}>Come & join our team.</h1>
            <div className="my-10 mx-4">
              <div className="border-b">
                <div className="flex pb-4 md:pb-5">
                  <div className="basis-10/12">
                    <div className="flex flex-col justify-between  md:flex-row">
                      <div className="text-sm md:text-base pb-[6px] md:pb-0">Brand Manager - Full Time</div>
                      <div className="flex float-right text-brown items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="w-5 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                          <path strokeLinecap="round" strokeLinejoin="round"
                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                        </svg>
                        <div className="text-base pl-1 text-sm md:text-base">Docklands VIC</div>
                      </div>
                    </div>
                  </div>
                  <div className="basis-2/12 self-center">
                    <div className="float-right">
                      <a href="mailto:info@wewumbo.io" className="text-blue font-normal">Apply</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="Story" />

export default StoryPage
