import * as React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import queryString from "query-string"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
const recaptchaRef = React.createRef()
class ContactPage extends React.Component {
  state = {
    validated: false,
    submitting: false,
    message: false,
    reCaptchaToken: "",
    isValidEmail: false,
    isShow: false,
    isResident: false,
  }
  handleSubmit = async e => {
    e.preventDefault()
    const form = e.currentTarget
    const token = await recaptchaRef.current.executeAsync()

    if (!this.state.isValidEmail) return

    if (
      !this.role.value ||
      !this.company.value ||
      !this.name.value ||
      !this.email.value ||
      !this.subject.value ||
      !this.message.value
    ) {
      e.stopPropagation()
    } else {
      this.setState({ submitting: true })

      const body = {
        role: this.role.value,
        name: this.name.value,
        building: this.company.value,
        email: this.email.value,
        subject: this.subject.value,
        message: this.message.value,
        "g-recaptcha-response": token,
      }

      fetch(
        "https://uyz342t079.execute-api.ap-southeast-2.amazonaws.com/latest/contact/sendmail-captcha",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: queryString.stringify(body),
        }
      )
        .then(r => r.json())
        .then(() => {
          window.grecaptcha.reset()
          this.formContact.reset()

          this.setState({
            submitting: false,
            validated: false,
            message: true,
          })
        })
        .catch(() => {
          this.setState({ submitting: false })
        })
    }
  }

  onSelect = even => {
    if (even.target.value !== "resident") {
      this.setState({ isShow: true })
    } else {
      this.setState({ isShow: false })
      this.setState({ isResident: true })
    }
  }

  handleMessage = () => {
    this.setState({
      message: false,
    })
  }

  isValidEmail(email) {
    const regEx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
    if (!regEx.test(email)) {
      return false
    }
    return true
  }

  render() {
    const { location } = this.props

    const {
      validated,
      submitting,
      message,
      reCaptchaToken,
      isShow,
      isResident,
    } = this.state
    return (
      <Layout pageTitle="Contact" location={location}>
        <div className="mx-auto md:my-[180px] mx-auto px-4 text-content text-white md:px-[80px] xl:px-[180px] 2xl:px-auto">
          <div className="flex flex-col md:flex-row md:gap-5 md:max-w-[1080px] md:mx-auto">
            <div className="flex-auto md:max-w-[50%] mb-10">
              <h1 className="uppercase head-text text-[24px] md:text-[40px]">
                Hello.
              </h1>
              <p className="py-2 md:py-5 text-[14px] font-[17px]">
                We're happy to answer any questions you may have or provide you
                with some additional information about WE Wumbo.
              </p>
              <p className="py-2 md:pt-5 text-[14px] font-[17px]">
                <b className="bold-text">Melbourne Office</b> <br /> Level 1, 58
                Lorimer St, Docklands VIC 3008
              </p>
              <p className="md:pt-2 text-[14px] font-[17px]">+61 3 9600 2999</p>
            </div>
            <div className="flex-auto md:max-w-[50%]">
              <form
                action=""
                className=""
                onSubmit={this.handleSubmit}
                ref={el => {
                  this.formContact = el
                }}
              >
                <div className="flex flex-col">
                  <div className="md:mt-[10px] mb-6 md:mb-[24px]">
                    <select
                      name="role"
                      className="block w-full mt-1 form-select-custom"
                      ref={el => {
                        this.role = el
                      }}
                      onChange={e => this.onSelect(e)}
                    >
                      <option value="">I am a ...</option>
                      <option value="strata-manage">Strata Manager</option>
                      <option value="facility-manage">Facility Manager</option>
                      <option value="property-develop">
                        Property Developer
                      </option>
                      <option value="potential-partner">
                        Potential Partner
                      </option>
                      <option value="resident">Resident</option>
                    </select>
                  </div>
                  {isShow && (
                    <>
                      <div className="mb-6">
                        <input
                          name="building"
                          type="text"
                          className="block w-full mt-1 form-control"
                          placeholder="Company"
                          ref={el => {
                            this.company = el
                          }}
                        />
                      </div>
                      <div className="mb-6">
                        <input
                          name="name"
                          type="text"
                          className="block w-full mt-1 form-control"
                          placeholder="Name"
                          ref={el => {
                            this.name = el
                          }}
                        />
                      </div>
                      <div className="mb-6">
                        <input
                          name="email"
                          type="email"
                          className="block w-full mt-1 form-control"
                          placeholder="Email"
                          ref={el => {
                            this.email = el
                          }}
                          onChange={e => {
                            this.setState({
                              isValidEmail: this.isValidEmail(e.target.value),
                            })
                          }}
                        />
                      </div>
                      <div className="mb-6">
                        <input
                          name="subject"
                          type="text"
                          className="block w-full mt-1 form-control"
                          placeholder="Subject"
                          ref={el => {
                            this.subject = el
                          }}
                        />
                      </div>
                      <div className="mb-6">
                        <textarea
                          name="message"
                          rows="4"
                          className="block w-full mt-1 form-control h-auto"
                          placeholder="Message"
                          ref={el => {
                            this.message = el
                          }}
                        />
                      </div>
                      {message && (
                        <div className="mb-6">Your message has been sent</div>
                      )}

                      <div className="text-center md:text-right mb-20 md:mb-0">
                        <button type="submit"
                                className="bg-blue inline-flex items-center min-w-[160px] mx-auto px-4 py-2 justify-center leading-6 text-sm shadow rounded-md text-white transition ease-in-out duration-150"
                                disabled={submitting}>
                          {submitting && (
                            <><svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                                   fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                      stroke-width="4"></circle>
                              <path className="opacity-75" fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                              Processing...</>
                          )}
                          {!submitting && (
                            `Send`
                          )}
                        </button>
                      </div>
                    </>
                  )}
                  {isResident && !isShow && (
                    <p className="py-2 md:py-[10px] text-[14px] font-[17px]">
                      Please refer to our
                      <a
                        className="text-blue"
                        href={
                          "https://wumbotechnologies.atlassian.net/servicedesk/customer/portals"
                        }
                        target={`_blank`}
                      >
                        {" "}
                        Help Centre{" "}
                      </a>
                      or contact your building manager for assistance.
                    </p>
                  )}
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6LcHCc4ZAAAAAJrpbu2s2qiAFE1ZNs1P-GUy-T_f"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const Head = () => <Seo title="Contact" />

export default ContactPage