/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "It’s been an awesome year for us, as we welcomed new clients and new team members. Our communities continue to grow across Australia in all sizes, age groups, cultures and backgrounds experiencing the simplicity and ease with our WE Wumbo App for everyday communications and interactions. We are very excited about the year and the formation of key partnerships to launch new and exciting WE Wumbo features and initiatives in the new year!!!");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
