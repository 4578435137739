import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import {
  wrapper,
  wrapperInner,
} from "../styles/layout.module.scss"

const Layout = ({ location, children }) => {

  return (
    <div className={wrapper}>
      <Header location={location} pathname={location.pathname} />
      <div className={wrapperInner}>
        <main className="mt-20 md:my-[70px] min-h-[540px] md:min-h-none">
          {children}
        </main>
      </div>
      <Footer location={location} pathname={location.pathname} />
    </div>
  )
}

export default Layout
