import React, { useState } from 'react';

const ReadMore = ({text, limit, isShow}) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {setIsReadMore(!isReadMore)};
  const limitText = limit ? limit : 150;
  const wHiddenText = !!isShow;

  return (
    <>
      <span className='text-[14px] leading-[17px] md:text-[16px] md:leading-[19px] whitespace-pre-line'>
        {isReadMore ? text.slice(0, limitText) : text }
      </span>
        <span>
          {text.length > limitText &&
            <span onClick={toggleReadMore}>
              {isReadMore ?
                <p className="text-[17px] text-blue pt-[16px] cursor-pointer">Read More</p> :
                <p className="text-[17px] text-blue pt-[16px] cursor-pointer">Show Less</p>}
            </span>
          }
      </span>
    </>
  )
};

export default ReadMore;