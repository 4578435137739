import * as React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { StaticImage } from "gatsby-plugin-image"
import { imageBackground, section4, textBackground  } from "../../styles/reward.module.scss"

const RewardPage = ({ location }) => {
  return (
    <Layout pageTitle="Wumbo Club" location={location}>
      <div className="container mx-auto px-4 text-white">
        <section className="flex h-[742px] md:h-[900px] my-20 md:my-0 justify-center items-center">
          <div className="container mx-auto md:px-4 text-content text-center text-white">
            <div className="md:max-w-2xl mx-[70px] md:mx-auto">
              <StaticImage
                alt="australia-108-melbourne"
                src="../../images/reward/Web_83_Wumbo Club Logo.png"
              />
            </div>
            <div className="max-w-3xl mx-auto">
              <p className="py-[23px] md:py-[40px] text-sm md:text-base">
                Welcome to Wumbo Club exclusively for WE Wumbo residents.
                At your fingertips connect to your local and surrounding community's special deals
                and promotions curated for you.
              </p>
            </div>
          </div>
        </section>
      </div>
      <section className="relative">
        {/*<div className="h-[406px] md:h-[900px] reward-section-2-image-bg" />*/}
        <div className="w-hidden">
          <StaticImage
            alt="drop-off-collect.png"
            className=""
            src="../../images/reward/Moblie_84_Wumbo Rewards.png"
          />
        </div>
        <div className="mb-hidden">
          <StaticImage
            alt="drop-off-collect.png"
            className="md:max-h-[700px] 2xl:max-h-[900px]"
            src="../../images/reward/Web_84_Wumbo Rewards.png"
          />
        </div>
        <div className="absolute inset-0 m-auto overflow-auto w-full h-fit">
          <div className="overflow-y-hidden text-center px-[70px] md:px-[0]">
            <StaticImage
              alt="australia-108-melbourne"
              src="../../images/reward/Web_85_Wumbo Rewards Logo.png"
            />
          </div>
        </div>
      </section>
      <section className="text-white mb-[60px] md:mb-[0]">
        <div className="container mx-auto md:mb-[80px] px-[16px] text-white text-center">
          <div className="my-[40px] md:my-[80px] md:px-[80px] xl:px-[180px] 2xl:px-auto mx-auto">
           <div className="text-sm md:text-base md:max-w-[1080px] md:mx-auto">Get cashback or a discount on products, services and experiences when you shop at one of our 4,500 partners. With a $7/month membership,
a single person can save over $2,000, couples can save over $4,000 and couple with kids can save over $6,000 yearly!</div>
          </div>
          <div className="overflow-x-auto md:px-[80px] xl:px-[180px] 2xl:px-auto md:mx-auto">
            <div className="flex md:flex-row md:max-w-[1080px] md:mx-auto">
              <div className="w-2/5 md:w-2/12 px-[8px] flex-none text-center">
                <StaticImage
                  alt="Fitness-Group.png"
                  className={imageBackground}
                  src="../../images/reward/Web_86_Body & Soul.png"
                />
                <h2 className="py-5 uppercase text-sm font-bold md:text-base head-text">body & soul</h2>
              </div>
              <div className="w-2/5 md:w-2/12 px-[8px] flex-none text-center">
                <StaticImage
                  alt="Movie-Group.png"
                  className={imageBackground}
                  src="../../images/reward/Web_87_Cashback.png"
                />
                <h2 className="py-5 uppercase text-sm font-bold md:text-base head-text">cashback offers</h2>
              </div>
              <div className="w-2/5 md:w-2/12 px-[8px] flex-none text-center">
                <StaticImage
                  alt="Wine-Group.png"
                  className={imageBackground}
                  src="../../images/reward/Web_88_Entertainment.png"
                />
                <h2 className="py-5 uppercase text-sm font-bold md:text-base head-text">entertainment</h2>
              </div>
              <div className="w-2/5 md:w-2/12 px-[8px] flex-none text-center">
                <StaticImage
                  alt="Pet-Group.png"
                  className={imageBackground}
                  src="../../images/reward/Web_89_Gift Cards.png"
                />
                <h2 className="py-5 uppercase text-sm font-bold md:text-base head-text">gift cards</h2>
              </div>
              <div className="w-2/5 md:w-2/12 px-[8px] flex-none text-center">
                <StaticImage
                  alt="Pet-Group.png"
                  className={imageBackground}
                  src="../../images/reward/Web_90_Travel.png"
                />
                <h2 className="py-5 uppercase text-sm font-bold md:text-base head-text">travel</h2>
              </div>
              <div className="w-2/5 md:w-2/12 px-[8px] flex-none text-center">
                <StaticImage
                  alt="Pet-Group.png"
                  className={imageBackground}
                  src="../../images/reward/Web_91_Home & Lifestyle.png"
                />
                <h2 className="py-5 uppercase text-sm font-bold md:text-base head-text">home & lifestyle</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={section4}>
        {/*<div className="h-[406px] md:h-[900px] reward-section-4-image-bg" />*/}
        <div className="bg-white opacity-60">
          <div className="w-hidden">
            <StaticImage
              alt="drop-off-collect.png"
              className=""
              src="../../images/reward/nmg-network-mb.png"
            />
          </div>
          <div className="mb-hidden">
            <StaticImage
              alt="drop-off-collect.png"
              className="md:max-h-[700px] 2xl:max-h-[900px]"
              src="../../images/reward/nmg-networkjpg.jpg"
            />
          </div>
        </div>
        <div className="absolute inset-0 m-auto overflow-auto w-full h-fit">
          <div className="overflow-y-hidden text-center">
            <div className="max-w-7xl mx-auto text-white">
              <h1 className={textBackground}>Lifestyle is Coming Soon.</h1>
            </div>
            <div className="max-w-2xl mx-[16px] md:mx-auto pt-[16px] md:pt-[40px] text-white">
               <div className="text-sm md:text-base">At the touch of a button, Wumbo Lifestyle connects you with local community businesses
and amenities including cafes, restaurants, hair salons, fitness centres, and many more...</div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const Head = () => <Seo title="Wumbo Club" />

export default RewardPage
