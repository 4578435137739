import * as React from 'react'
import { useEffect, useRef, useState } from "react"
import { isMobileOnly } from "react-device-detect"
import Slider from "react-slick"
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { section2, section3, textContent, textHeader, textRedBackground } from "../../styles/smart-building.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import { imageBackground } from "../../styles/index.module.scss"
import gameGifMb from "../../images/smart-building/Mobile_Integrated-Digital-Ecosystem.gif"
import gameGif from "../../images/smart-building/Web_61_Integrated-Digital-Ecosystem-w.gif"

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, content: "none"}}
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
           className="w-10 h-10 text-white">
        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
      </svg>
    </div>
  );
}

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, content: "none"}}
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
           stroke="currentColor" className="w-10 h-10 text-white">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
      </svg>
    </div>
  );
}

const SmartBuildingPage = ({ location }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ],
  };

  const [width, setWidth] = useState(0)
  const [offset, setOffset] = useState(0)
  const [firstOffset, setFirstOffset] = useState(1234)
  const [smartOffset, setSmartOffset] = useState(2103)

  const firstRef = useRef();
  const smartRef = useRef();

  useEffect(() => {
    const onScroll = () => {
      setWidth(window.innerWidth)
      setOffset(window.pageYOffset)

      setFirstOffset(firstRef.current.offsetTop)
      setSmartOffset(smartRef.current.offsetTop)
    }
    window.removeEventListener("scroll", onScroll)
    window.addEventListener("scroll", onScroll, { passive: true })
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  const firstClassEffect = () => {
    // console.log(offset)
    // console.log(firstOffset)
    let className = '';
    if (width > 768 && offset > 845 && firstOffset - offset < 70) {
      className = 'fixed__smart';
    }
    if (width < 768 && offset > 800 && firstOffset - offset < 0) {
      className = 'fixed__smart';
    }

    return className;
  };

  return (
    <Layout pageTitle="Smart building" location={location}>
      <section className={section2}>
        <div className="w-hidden">
          <StaticImage
            alt="australia-108-melbourne"
            src="../../images/smart-building/Mobile_Building Tomorrow Today.png"
          />
        </div>
        <div className="mb-hidden">
          <StaticImage
            className="md:max-h-[700px] 2xl:max-h-[900px]"
            alt="australia-108-melbourne"
            src="../../images/smart-building/Web_59_Building.png"
          />
        </div>
      </section>
      <div className="mx-auto text-white">
        <section className={section3}>
          <div className="md:max-w-[1080px] md:mx-auto">
            Tomorrow’s buildings need to be more than just showpieces for unique ideas and plans. They should be smarter, safer and more efficient to
            improve and enriched the lives of people within them. WE Wumbo has joined the dots… with our group of industry innovators to co create
            Smart Building / Smart Community with our integrated digital ecosystem designed to improve operational efficiencies, reduced costs and
            most importantly enriched resident experiences.
          </div>
        </section>
      </div>
      <div
        ref={firstRef}
        className={firstClassEffect()}
        style={{opacity: smartOffset - offset > 0 ? 1 : 0}}
      >
        <section className="relative bg-white md:opacity-100">
          <div className="w-hidden">
            <StaticImage
              alt="drop-off-collect.png"
              className=""
              src="../../images/smart-building/Mobile_60_The Way.png"
            />
          </div>
          <div className="mb-hidden">
            <StaticImage
              alt="drop-off-collect.png"
              className="h-[375px] 2xl:h-[450px]"
              src="../../images/smart-building/Web_60_The Way.png"
            />
          </div>
          <div className="absolute inset-1 m-auto overflow-auto w-full h-fit">
            <div className="overflow-y-hidden text-white text-center">
              <h1 className={textRedBackground}>The Way Forward.</h1>
            </div>
          </div>
        </section>
        {width > 768 && (
          <div className="mx-auto text-white">
            <section className={section3}>
              <div className="md:max-w-[1080px] md:mx-auto">
                <span>
                We have explored the future and have developed the solutions to transform how vertical communities and master-planned estates are planned, design, sold/
              lease, operated, enriched living environments and resident experiences. It’s our creation of a <b className="bold-text"> smart building / smart community</b> that achieves new
              standards of<b className="bold-text"> sustainability, safety, affordability, mobility, people interactions and economic opportunity.</b>
              <br/>
              <br/>
              The result is a resilient future of <b className="bold-text"> cost and energy savings, improved services delivery, better quality of life and a reduced environmental footprint. </b>
              A community residents boast about, drives people to and businesses want to be part of.
              </span>
              </div>
            </section>
          </div>
        )}
      </div>

      <div className="fixed__spacer">
        <div className="fixed__spacer-inner" style={{height: isMobileOnly ? '406px' : '870px'}}></div>
      </div>

      <div className="section-relative md:px-[80px] xl:px-[180px] 2xl:px-auto" ref={smartRef}>
        {width < 768 && (
          <div className="container mx-auto text-white">
            <section className={section3}>
              <p>
                We have explored the future and have developed the solutions to transform how vertical communities and master-planned estates are planned, design, sold/
              lease, operated, enriched living environments and resident experiences. It’s our creation of a <b className="bold-text"> smart building / smart community</b> that achieves new
              standards of<b className="bold-text"> sustainability, safety, affordability, mobility, people interactions and economic opportunity.</b>
              <br/>
              <br/>
              The result is a resilient future of <b className="bold-text"> cost and energy savings, improved services delivery, better quality of life and a reduced environmental footprint.</b>
              A community residents boast about, drives people to and businesses want to be part of.
              </p>
            </section>
          </div>
        )}
        <div className="container px-4 mx-auto">
          <div className="mx-auto text-white md:max-w-[1080px] md:mx-auto">
            <section className="flex my-20 md:my-[180px] xl:my-[100px] 2xl:my-[180px] justify-center items-center">
              <div className="text-content text-center text-white 2xl:px-auto">
                <div className="mx-auto py-[40px] md:pb-[80px]">
                  <p className="md:py-[32px] md:text-[32px] md:leading-[32px] text-[24px] leading-[24px] head-text">
                    Our fully integrated digital ecosystem is the convergence of physical, human and digital innovations inside and outside the home.
                  </p>
                </div>
                <div className="max-w-[200px] md:max-w-[400px] lg:max-w-[600px] xl:max-w-[600px] 2xl:max-w-[800px] mx-auto">
                  <img className="w-hidden w-full" src={gameGifMb} alt="Otter dancing with a fish" />
                  <img className="mb-hidden w-full h-auto" src={gameGif} alt="Otter dancing with a fish" />
                </div>
              </div>
            </section>
          </div>
          <div className="mx-auto text-white md:max-w-[1080px] md:mx-auto">
            <section className="py-20 md:pt-0 md:pb-[80px] mx-4 text-center">
              <h1 className={textHeader}>Strength in Numbers.</h1>
              <div className="pt-[22px] md:pt-10">
                <p className={textContent}>
                  Our collaboration partners share our vision and standards when it comes to innovation, quality and passion to do great things together.
                  We have carefully explored and identified leaders in their respective industry to join us in co-creation of Smart Building / Smart Community to ensure our solution ecosystem is of the highest quality and more importantly sustainable.
                </p>
              </div>
              <div className="pt-6 md:py-10">
                <p className={textContent}>
                  We are a group of industry innovators co-creating Smart Building / Smart Communities in conjunction with Property Developers.
                  Our solutions are the best of todays’ technology and we continue to explore future innovations to refine and enhance our platform.
                </p>
              </div>
              <div className="py-[40px] md:py-10">
                <div className="mb-hidden">
                  <Slider {...settings}>
                    <div className="px-[8px] md:px-[12px] text-center">
                      <StaticImage
                        alt="drop-off-collect.png"
                        className={imageBackground}
                        src="../../images/smart-building/Web_67_Strenth.png"
                      />
                      <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Intercoms &
                        Home Automations</h2>
                    </div>
                    <div className="px-[8px] md:px-[12px] text-center">
                      <StaticImage
                        alt="drop-off-collect.png"
                        className={imageBackground}
                        src="../../images/smart-building/Web_68_Strenth.png"
                      />
                      <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Access Control +
                        Security Installation</h2>
                    </div>
                    <div className="px-[8px] md:px-[12px] text-center">
                      <StaticImage
                        alt="drop-off-collect.png"
                        className={imageBackground}
                        src="../../images/smart-building/Web_69_Strenth.png"
                      />
                      <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Smart Locks &
                        Door Furniture</h2>
                    </div>
                    <div className="px-[8px] md:px-[12px] text-center">
                      <StaticImage
                        alt="drop-off-collect.png"
                        className={imageBackground}
                        src="../../images/smart-building/Web_70_Strenth.png"
                      />
                      <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Embedded Networks &
                        Renewable Energy</h2>
                    </div>
                    <div className="px-[8px] md:px-[12px] text-center">
                      <StaticImage
                        alt="drop-off-collect.png"
                        className={imageBackground}
                        src="../../images/smart-building/Web_71_Strenth.png"
                      />
                      <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Smart Lockers</h2>
                    </div>
                    <div className="px-[8px] md:px-[12px] text-center">
                      <StaticImage
                        alt="drop-off-collect.png"
                        className={imageBackground}
                        src="../../images/smart-building/Web_72_Strenth.png"
                      />
                      <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Integrated Access Control systems</h2>
                    </div>
                  </Slider>
                </div>
                <div className="mb-hidden md:py-10">
                  <Slider {...settings}>
                    <div className="px-[8px] md:px-[12px] text-center">
                      <StaticImage
                        alt="drop-off-collect.png"
                        className={imageBackground}
                        src="../../images/smart-building/Web_75_Strenth.png"
                      />
                      <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text"> In-Home Services platform</h2>
                    </div>
                    <div className="px-[8px] md:px-[12px] text-center">
                      <StaticImage
                        alt="drop-off-collect.png"
                        className={imageBackground}
                        src="../../images/smart-building/Web_76_Strenth.png"
                      />
                      <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Rewards & Loyalty program</h2>
                    </div>
                    <div className="px-[8px] md:px-[12px] text-center">
                      <StaticImage
                        alt="drop-off-collect.png"
                        className={imageBackground}
                        src="../../images/smart-building/Web_79_Strenth.png"
                      />
                      <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Defects & Reporting</h2>
                    </div>
                    <div className="px-[8px] md:px-[12px] text-center">
                      <StaticImage
                        alt="drop-off-collect.png"
                        className={imageBackground}
                        src="../../images/smart-building/Web_80_Strenth.png"
                      />
                      <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Mortgage Lead generation</h2>
                    </div>
                    <div className="px-[8px] md:px-[12px] text-center">
                      <StaticImage
                        alt="drop-off-collect.png"
                        className={imageBackground}
                        src="../../images/smart-building/Web_73_Strenth.png"
                      />
                      <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Mobile Access technology</h2>
                    </div>
                    <div className="px-[8px] md:px-[12px] text-center">
                      <StaticImage
                        alt="drop-off-collect.png"
                        className={imageBackground}
                        src="../../images/smart-building/Web_74_Strenth.png"
                      />
                      <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Smart parking bollards</h2>
                    </div>
                  </Slider>
                </div>
                <div className="w-hidden">
                  <div className="overflow-x-auto md:overflow-x-hidden">
                    <div className="flex flex-nowrap gap-4 md:justify-center">
                      <div className="w-2/5 md:w-fit flex-none text-center">
                        <StaticImage
                          alt="drop-off-collect.png"
                          className={imageBackground}
                          src="../../images/smart-building/Web_67_Strenth.png"
                        />
                        <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Intercoms &
                          Home Automations</h2>
                      </div>
                      <div className="w-2/5 md:w-fit flex-none text-center">
                        <StaticImage
                          alt="drop-off-collect.png"
                          className={imageBackground}
                          src="../../images/smart-building/Web_68_Strenth.png"
                        />
                        <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Access Control +
                          Security Installation</h2>
                      </div>
                      <div className="w-2/5 md:w-fit flex-none text-center">
                        <StaticImage
                          alt="drop-off-collect.png"
                          className={imageBackground}
                          src="../../images/smart-building/Web_69_Strenth.png"
                        />
                        <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Smart Locks &
                          Door Furniture</h2>
                      </div>
                      <div className="w-2/5 md:w-fit flex-none text-center">
                        <StaticImage
                          alt="drop-off-collect.png"
                          className={imageBackground}
                          src="../../images/smart-building/Web_70_Strenth.png"
                        />
                        <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Embedded Networks &
                          Renewable Energy</h2>
                      </div>
                      <div className="w-2/5 md:w-fit flex-none text-center">
                        <StaticImage
                          alt="drop-off-collect.png"
                          className={imageBackground}
                          src="../../images/smart-building/Web_71_Strenth.png"
                        />
                        <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Smart Lockers</h2>
                      </div>
                      <div className="w-2/5 md:w-fit flex-none text-center">
                        <StaticImage
                          alt="drop-off-collect.png"
                          className={imageBackground}
                          src="../../images/smart-building/Web_72_Strenth.png"
                        />
                        <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Integrated Access Control systems</h2>
                      </div>
                    </div>
                    <br/>
                    <div className="flex flex-nowrap gap-4 md:justify-center">
                      <div className="w-2/5 md:w-fit flex-none text-center">
                        <StaticImage
                          alt="drop-off-collect.png"
                          className={imageBackground}
                          src="../../images/smart-building/Web_75_Strenth.png"
                        />
                        <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text"> In-Home Services platform</h2>
                      </div>
                      <div className="w-2/5 md:w-fit flex-none text-center">
                        <StaticImage
                          alt="drop-off-collect.png"
                          className={imageBackground}
                          src="../../images/smart-building/Web_76_Strenth.png"
                        />
                        <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Rewards & Loyalty program</h2>
                      </div>
                      <div className="w-2/5 md:w-fit flex-none text-center">
                        <StaticImage
                          alt="drop-off-collect.png"
                          className={imageBackground}
                          src="../../images/smart-building/Web_79_Strenth.png"
                        />
                        <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Defects & Reporting</h2>
                      </div>
                      <div className="w-2/5 md:w-fit flex-none text-center">
                        <StaticImage
                          alt="drop-off-collect.png"
                          className={imageBackground}
                          src="../../images/smart-building/Web_80_Strenth.png"
                        />
                        <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Mortgage Lead generation</h2>
                      </div>
                      <div className="w-2/5 md:w-fit flex-none text-center">
                        <StaticImage
                          alt="drop-off-collect.png"
                          className={imageBackground}
                          src="../../images/smart-building/Web_73_Strenth.png"
                        />
                        <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Mobile Access technology</h2>
                      </div>
                      <div className="w-2/5 md:w-fit flex-none text-center">
                        <StaticImage
                          alt="drop-off-collect.png"
                          className={imageBackground}
                          src="../../images/smart-building/Web_74_Strenth.png"
                        />
                        <h2 className="py-4 md:py-5 uppercase text-sm md:text-base head-text">Smart parking bollards</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="mx-auto text-white md:max-w-[1080px] md:mx-auto">
            <section className="text-center md:pt-20">
              <h1 className={textHeader}>Seamless Integration.</h1>
              <div className="pt-[22px] md:pt-10 px-[16px] md:mx-[15px]">
                <p className={textContent}>
                  At the core of the ecosystem is our WE Wumbo platform (App) - building management & resident communications, connected to hardware infrastructure functions and controls are interlinked and communicate seamlessly from the building lobby, to the lift and inside the home, all accessible from a smart mobile phone and or tablet. The genius of our highly sophisticated system, is its ease-of-use. From the basic configuration to the high-end comfort / luxury solution everything is possible.
                </p>
              </div>
              <div className="max-w-3xl py-10 mx-auto">
                <StaticImage
                  alt="australia-108-melbourne"
                  src="../../images/smart-building/ecosystem.png"
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="Smart Building" />

export default SmartBuildingPage
