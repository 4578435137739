import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import {
  wrapper,
  wrapperInnerDetail,
} from "../styles/layout.module.scss"

const LayoutDetail = ({ location, children }) => {

  return (
    <div className={wrapper}>
      <Header location={location} />
      <div className={wrapperInnerDetail}>
        <main className="mt-[70px] bg-white text-black">
          {children}
        </main>
      </div>
      <Footer location={location} pathname={location.pathname} />
    </div>
  )
}

export default LayoutDetail
