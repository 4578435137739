import * as React from 'react'
import LayoutDetail from '../../components/layout-detail'
import Seo from '../../components/seo'

const TermConditionsPage = ({ location }) => {
  return (
    <LayoutDetail pageTitle="Term & Conditions" location={location}>
      <div className="mx-auto">
        <div className="px-4 md:mt-10 text-black md:px-[80px] xl:px-[180px] 2xl:px-auto">
          <div className="md:max-w-[1080px] md:mx-auto py-10">
            <h2 className="text-[24px] leading-[24px] bold-text">Terms & Conditions</h2>
            <p className="text-[12px] leading-[12px] text-brown mt-[24px] mb-[12px]">Effective Date: 1st September 2022</p>
            <hr className="text-brown pb-5"/>
            <div className="text-black text-sm md:text-base">
              The following Terms and Conditions (these “Terms”) describe your rights and responsibilities as a subscriber and/or user of the WE Wumbo App (the “APP”).
              <br/>
              <br/>
              These Terms are between you and WE Wumbo Pty Ltd (“WE Wumbo,” “we”,” our” or “us”), who owns and operates the APP. “You” means the entity you represent in accepting these Terms or, if that does not apply, you, individually.
              <br/>
              <br/>
              Our complete terms and conditions are contained below, but some important points for you to know before you become a customer are set out below:
              <br/>
              <ul className="list-disc pl-[40px]">
                <li>We may amend these Terms, the features of the APP at any time, by providing written notice to you;
                </li>
                <li>Your Membership is subject to a minimum term, as set out in your Account. Without limiting your
                  rights under the Australian Consumer Law, you may only cancel your Membership at the expiry of the
                  minimum term;
                </li>
                <li>We will handle your personal information in accordance with our privacy policy, available at
                  https://www.wewumbo.io/privacy.
                </li>
                <li>You are responsible and liable for the actions of your End Users;</li>
                <li>Our liability under these Terms is limited to the Fees paid by you to us and we will not be liable
                  for Consequential Loss, any loss that is a result of a Third-Party Service, or any loss or corruption
                  of data;
                </li>
                <li>We may terminate your Membership at any time by giving 60 days’ written notice to you; and</li>
                <li>We may receive a benefit (which may include a referral fee or a commission) should you visit certain
                  third-party websites via a link on the Platform or for featuring certain products or services on the
                  Platform.
                </li>
              </ul>
              <ul className="list-decimal pl-[40px]">
                <li>
                  <strong>Your Acceptance.</strong><br/>
                  By using the APP, you signify your agreement to these Terms. <br/>
                  You have read and understand these Terms; and you agree to these Terms on behalf of the party that you represent.
                </li>
                <li>
                  <strong>Our Services</strong><br/>
                  We provide a software platform that facilitates the management & operation of residential, commercial, and special-purpose buildings (the “Service”). As we are a software provider and platform facilitator, we engage some independent third-party providers (“Third Party Providers) and WE Wumbo affiliates in the provision of Additional Services.
                </li>
                <li>
                  <strong>Changes to the Service</strong><br/>
                  We are constantly changing and improving the Service. We may also need to alter or discontinue the Service, or any part of it, to make performance or security improvements, change functionality and features, make changes to comply with law, or prevent illegal activities on or abuse of our systems. These changes may affect all users, some users or even an individual user. Whenever reasonably possible, we will provide notice when we discontinue or make material changes to our Service that will have an adverse impact on the use of our Service. However, you understand and agree that there will be times when we make such changes without notice, such as where we feel we need to take action to improve the security and operability of our Service, prevent abuse, or comply with legal requirements.
                </li>
                <li>
                  <strong>Pricing and Fees.</strong><br/>
                  Your Account specifies the Contract Term, Fees, and Billing Period. <br/>
                  <strong>Subscription Term. </strong>The minimum Subscription Term 12 months. <br/>
                  <ul>
                    <li><label className="sc-gipzik kyeIcb">4.1 </label> <strong>Fees.</strong> Fees means the fees and
                      charges relating to the use of the Service, as stated in your Account.
                    </li>
                    <li><label className="sc-gipzik kyeIcb">4.2 </label><strong>Billing Period.</strong> The Billing
                      Period is the option you choose for payment of fees when creating an Account on the App. You may
                      choose the 6-month Billing Period or the 12-month Billing Period.
                      <ul className="sc-jDwBTQ efbqVU">
                        <li><label className="sc-gipzik kyeIcb">4.2.1 </label>6 Month Billing Period: You will be
                          charged upfront for 6 months from the date your Subscription Term begins (Payment Date). On
                          the 6-month anniversary of your Payment Date, you will be automatically charged the same Fees
                          for another 6 months.
                        </li>
                        <li><label className="sc-gipzik kyeIcb">4.2.2 </label>12 Month Billing Period: You will be
                          charged upfront for 12 months from the date your Subscription Term begins (Payment Date).
                        </li>
                      </ul>
                    </li>
                    <li><label className="sc-gipzik kyeIcb">4.3 </label> <strong>Payment Terms.</strong> Payment is due
                      14 days from the date of invoice. Payment must be made upfront before the start of your
                      Subscription Term for the Billing Period you selec
                    </li>
                    <li><label className="sc-gipzik kyeIcb">4.4 </label><strong>Subscription Renewal.</strong> At the
                      expiry of your Minimum Term, your subscription will renew (for a period equal to your Subscription
                      Term) if you provide us with instructions for renewal. We will notify you 14 days before the
                      expiry of your Minimum Term to seek your preference for renewal.
                    </li>
                    <li><label className="sc-gipzik kyeIcb">4.5 </label><strong>Changes to Fees.</strong> By giving you
                      at least 60 days’ notice before the change takes effect, we may:
                      <ul>
                        <li><label className="sc-gipzik kyeIcb">i. </label> change the amount of any fee or a new fee;
                          and
                        </li>
                        <li><label className="sc-gipzik kyeIcb">ii. </label>change the circumstances in which, or
                          frequency with which, a fee is payable.
                        </li>
                      </ul>
                    </li>
                    <li><label className="sc-gipzik kyeIcb">4.6 </label><strong>Termination rights.</strong> If we
                      change the Fees under Section 4.5 above, then you can terminate your Subscription at the end of
                      your Subscription Term.<br/>You can action a termination by emailing us at accounts@wewumbo.io.
                    </li>
                    <li><label className="sc-gipzik kyeIcb">4.7 </label><strong>Early Termination Fee.</strong> <br/>If
                      you terminate your subscription before the expiry of the Subscription Term for reasons other those
                      in Section 10.2, then you must pay the Fees for the remainder of the Subscription Term (our Early
                      Termination Fee).</li>
                  </ul>
                </li>
                <li>
                  <strong>How the APP is administered.</strong> <br/>
                  <ul>
                    <li><label className="sc-gipzik kyeIcb">5.1 </label><strong>Account.</strong> You must register on
                      the APP and create an account (Account) to access the APP’s features. You must provide basic
                      information when registering for an Account including your business name, contact name and email
                      address and you must choose a username and password.
                    </li>
                    <li><label className="sc-gipzik kyeIcb">5.2 </label><strong>Administrators.</strong> Through the
                      APP, you may be able to specify certain End Users as Administrators, who will have important
                      rights and controls over your use of the APP and End User Accounts. This may include activating
                      features (which may incur fees); creating, de- provisioning, monitoring, or modifying End User
                      Accounts, and setting End User usage permissions; and managing access to Your Data by End Users or
                      others. Administrators may also take over management of accounts previously registered using an
                      email address belonging to your domain. Without limiting Section 5.4 (Responsibility for End
                      Users), which fully applies to Administrators, you are responsible for whom you allow to become
                      Administrators and any actions they take, including as described above. You agree that our
                      responsibilities do not extend to the internal management or administration of the APP for you.
                    </li>
                    <li><label className="sc-gipzik kyeIcb">5.3 </label><strong>End User Consent.</strong> You will
                      provide all disclosures to and will obtain and maintain all required consents from End Users to
                      allow: (i) Administrators to have the access described in these Terms and the Privacy Policy; and
                      (ii) our provision of the APP to Administrators and End Users
                    </li>
                    <li><label className="sc-gipzik kyeIcb">5.4 </label><strong>Responsibility for End
                      Users.</strong> Our APP has various user inputs. You are responsible for understanding the
                      settings and controls for each feature you use and for controlling whom you allow to become an End
                      User. Our APP may allow you to designate different types of End Users, in which case functionality
                      may vary according to the type of End User. You are responsible for compliance with these Terms by
                      all End Users, including for any payment obligations. Please note that you are responsible for the
                      activities of all your End Users, including how End Users use Your Data. We may display our User
                      Notice to End Users at account registration, or in-product.
                    </li>
                    <li><label className="sc-gipzik kyeIcb">5.5 </label><strong>Credentials.</strong> User IDs are
                      granted to individual, named persons, and may not be shared. You are responsible for any and all
                      actions taken using End User Accounts and passwords.
                    </li>
                    <li><label className="sc-gipzik kyeIcb">5.6 </label><strong>Age Requirement for End
                      Users.</strong> The APP is not intended for, intended for, and should not be used by, anyone under
                      the age of 18. You are responsible for ensuring that all End Users are age appropriate.
                    </li>
                    <li><label className="sc-gipzik kyeIcb">5.7 </label><strong>Text Messaging.</strong> By creating an
                      Account, you agree that the Services may send you text (SMS) messages as part of the normal
                      business operation of your use of the Services. You may opt-out of receiving text (SMS) messages
                      from the APP at any time by following the directions found at www.wewumbo.io. You acknowledge that
                      opting out of receiving text (SMS) messages may impact your use of the Services. SMS is charged at
                      $0.10 per message and is billed on a quarterly basis.
                    </li>
                    <li><label className="sc-gipzik kyeIcb">5.8 </label><strong>Privacy:</strong> All personal
                      information you and Users provide to us will be treated in accordance with our Privacy Policy.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>What is included in your subscription; what are the restrictions.</strong><br/>
                  <ul>
                    <li><label className="sc-gipzik kyeIcb">6.1 </label><strong>Access to APP</strong> <br/>Subject to
                      these Terms and during the applicable Subscription Term, you may access and use the APP for your
                      own business purposes or personal use, as applicable. This includes the right, as part of your
                      authorized use of the APP, to download and use the software associated with the APP. The rights
                      granted to you are non-exclusive, non-sublicensable and non- transferable.</li>
                    <li><label className="sc-gipzik kyeIcb">6.2 </label><strong>Support</strong> <br/>During the
                      Subscription Term, we will provide Support for the APP in accordance with our Service Level
                      Agreement (“SLA”).</li>
                    <li><label className="sc-gipzik kyeIcb">6.3 </label><strong>Restrictions</strong> <br/>Except as
                      otherwise expressly permitted in these Terms, you will not: (a) reproduce, modify, adapt, or
                      create derivative works of the APP; (b) rent, lease, distribute, sell, sublicense, transfer or
                      provide access to the APP to a third party; (c) use the APP for the benefit of any third party;
                      (d) incorporate any APP into a product or service you provide to a third party; (e) interfere with
                      or otherwise circumvent mechanisms in the APP intended to limit your use; (f) reverse engineer,
                      disassemble, decompile, translate or otherwise seek to obtain or derive the source code,
                      underlying ideas, algorithms, file formats or non-public APIs to any APP, except to the extent
                      expressly permitted by applicable law (and then only upon advance notice to us); (g) remove or
                      obscure any proprietary or other notices contained in the APP; (h) use the APP for competitive
                      analysis or to build competitive products; (i) publicly disseminate information regarding the
                      performance of the APP; or (j) encourage or assist any third party to do any of the foregoing.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Terms that apply to Your Data.</strong><br/>
                  <ul>
                    <li><label className="sc-gipzik kyeIcb">7.1 </label><strong>Using Your Data to provide APP to
                      You.</strong> You retain all right, title and interest in and to Your Data in the form submitted
                      to the APP. Subject to these Terms, and solely to the extent necessary to provide the APP to you,
                      you grant us a worldwide, limited term license to access, use, process, copy, distribute, perform,
                      export, and display Your Data. Solely to the extent that reformatting Your Data for display in the
                      APP constitutes a modification or derivative work, the foregoing license also includes the right
                      to make modifications and derivative works. We may also access your accounts, End User Accounts,
                      and your APP with End User permission to respond to your support requests.
                    </li>
                    <li><label className="sc-gipzik kyeIcb">7.2 </label><strong>Your Data Compliance
                      Obligations.</strong> You and your use of the APP (including use by your End Users) must comply at
                      all times with these Terms and all Laws. You represent and warrant that: (i) you have obtained all
                      necessary rights, releases and permissions to submit all Your Data to the APP and to grant the
                      rights granted to us in these Terms and (ii) Your Data and its submission and use as you authorize
                      in these Terms will not violate (1) any Laws, (2) any third- party intellectual property, privacy,
                      publicity or other rights, or (3) any of your or third-party policies or terms governing Your
                      Data. We assume no responsibility or liability for Your Data, and you are solely responsible for
                      responsible for Your Data and the consequences of submitting and using it with the APP.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Using third-party providers within the APP.</strong> <br/>
                  <ul>
                    <li><label className="sc-gipzik kyeIcb">8.1 </label><strong>Third-Party Providers.</strong> The APP
                      may contain links to websites operated by third parties, such as Effi. Unless we tell you
                      otherwise, we do not control, endorse, or approve, and are not responsible for, the content on
                      those websites. We recommend that you make your own investigations with respect to the suitability
                      of those websites. If you purchase goods or services from a third-party website linked from the
                      Platform, such third party provides the goods and services to you, not us.
                    </li>
                    <li><label className="sc-gipzik kyeIcb">8.2 </label>We may receive a benefit (which may include a
                      referral fee or a commission) should you visit certain third-party websites via a link on the APP
                      (Affiliate Link) or for featuring certain products or services on the Platform.
                    </li>
                    <li><label className="sc-gipzik kyeIcb">8.3 </label>We receive a benefit, including a lead fee,
                      through your use of credit services provided by Effi's mortgage brokers on our platform.
                    </li>
                    <li><label className="sc-gipzik kyeIcb">8.4 </label>We are not responsible or liable for any loss or
                      damage you or any third-party may suffer or incur in connection with any product you obtain after
                      using this APP or for any acts, omissions, errors, or defaults of any third party in connection
                      with that product or service.
                    </li>
                    <li><label className="sc-gipzik kyeIcb">8.5 </label>By submitting your personal details through this
                      APP, you can make an offer to obtain a product or service from a third-party provider. That offer
                      may be accepted or rejected. The contract for the product will only be concluded once your offer
                      has been accepted by the relevant third-party provider. If your offer is accepted then the product
                      will be provided to you, subject to the third-party provider’s terms and conditions. You will
                      receive written confirmation if your offer has been accepted.
                    </li>
                    <li><label className="sc-gipzik kyeIcb">8.6 </label>You (including your End Users) may choose to use
                      or procure other third-party products or services in connection with the APP. Your receipt or use
                      of any third-party products or services (and the third parties’ use of any of Your Data) is
                      subject to a separate agreement between you and the third-party provider. Any third-party
                      provider’s use of Your Data is subject to the applicable agreement between you and such
                      third-party provider. We are not responsible for any access to or use of Your Data by third party
                      providers or their products or services, or for the security or privacy practices of any
                      third-party provider or its products or services. You are solely responsible for your decision to
                      permit any third-party provider or third-party product or service to use Your Data. It is your
                      responsibility to carefully review the agreement between you and the third-party provider, as
                      provided by the applicable third-party provider.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>IP Rights in the APP and Feedback.</strong><br/>
                  <ul>
                    <li><label className="sc-gipzik kyeIcb">9.1 </label>The APP is made available on a limited basis,
                      and no ownership right is conveyed to you. We retain all right, title, and interest, including all
                      intellectual property rights, in the APP. From time to time, you may choose to submit Feedback to
                      us. We may in connection with any of our products or services freely use, copy, disclose, license,
                      distribute and exploit any Feedback in any manner without any obligation, royalty or restriction
                      based on intellectual property rights or otherwise. No Feedback will be considered your
                      Confidential Information, and nothing in these Terms limits our right to independently use,
                      develop, evaluate, or market products or services, whether incorporating Feedback or otherwise.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>How can you end your Subscription?</strong><br/>
                  <ul className="sc-jDwBTQ efbqVU">
                    <li><label className="sc-gipzik kyeIcb">10.1 </label><strong>By notice.</strong> You can terminate
                      your by providing us with at least 60 days (Notice Period). Your Effective Termination Date will
                      be the date that satisfies the minimum required Notice Period or a date that we have mutually
                      agreed to. Your subscription will cease on the Effective Termination Date. You are liable for any
                      Fees in relation to your use of the APP up to the Effective Termination Date, and if applicable,
                      any Early Termination Fee described under Section 4.7.<br/>You can action a termination by emailing
                        us at<a className="text-blue" href="mailto:accounts@wewumbo.io"> accounts@wewumbo.io</a></li>
                    <li><label className="sc-gipzik kyeIcb">10.2 </label><strong>For breach.</strong> Either party may
                      terminate this agreement if they fail to remedy a material breach of Terms within 10 days of being
                      provided with written notice of the breach. If you terminate your subscription under this Section
                      10.2 during the Subscription Term, then the requirement to pay us Fees for the remainder of the
                      Subscription Term does not apply.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>How can we cancel your Subscription?</strong><br/>
                  <ul className="sc-jDwBTQ efbqVU">
                    <li><label className="sc-gipzik kyeIcb">11.1 </label>We can terminate or suspend your access 90
                      days’ notice if:
                      <ul className="list-disc bBpbXY">
                        <li>in our opinion, a change to any Third -Party arrangement necessary for the Service renders
                          the ongoing operation of the APP substantially unworkable or non-functional;
                        </li>
                        <li>in our opinion, the actions of a regulator or a change in law or regulation makes the
                          ongoing operation of the APP substantially unworkable or non-functional;
                        </li>
                        <li>we believe this is necessary either to protect the security, integrity or reputation of the
                          APP or any APP function, service, or facility, or to otherwise protect our interests;
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>What happens on Termination?</strong><br/>
                  <ul className="sc-jDwBTQ efbqVU">
                    <li><label className="sc-gipzik kyeIcb">12.1 </label><strong>Effective Termination
                      Date.</strong> Unless specified, Your Effective Termination Date will be the date that satisfies
                      the minimum required Notice Period.<br/>On the Effective Termination Date:
                        <ul className="list-disc bBpbXY">
                          <li>the rights granted under section 6.1 terminates and your use of the APP will cease; and
                          </li>
                          <li>you and any Users will only be able to access Your Data in accordance with Section 12.3
                            described below.
                          </li>
                        </ul></li>
                    <li><label className="sc-gipzik kyeIcb">12.2 </label><strong>Refund of Prepaid Subscription
                      Fee</strong>
                      <ul className="list-disc bBpbXY">
                        <li>If you terminate these Terms in accordance with Section 10 (How You Can Cancel Your
                          Subscription), or under Section 4.6 (Termination Rights), we will refund you any prepaid fees
                          covering the remainder of the then-current Subscription Term after the Effective Termination
                          Date.
                        </li>
                        <li>If we terminate these Terms in accordance with Section 11.1 (How We Can Cancel Your
                          Subscription), you will pay any unpaid fees covering the remainder of the then-current
                          Subscription Term.
                        </li>
                        <li>If we terminate these Terms in accordance with Section 11.1 (How We Can Cancel Your
                          Subscription), we will refund you any paid fees covering the remainder of the then-current
                          Subscription Term.
                        </li>
                        <li>In no event will termination relieve you of your obligation to pay any fees payable to us
                          for the period prior to the Effective Termination Date. The exercise by either party of any
                          remedy, including termination, will be without prejudice to any other remedies it may have
                          under these Terms, by law or otherwise.
                        </li>
                      </ul>
                    </li>
                    <li><label className="sc-gipzik kyeIcb">12.3 </label> <strong>Accessing archived Data
                      after</strong>termination. <br/>It is strongly recommended that you extract your Data as soon as
                      possible following termination of the APP. You acknowledge and agree that:
                      <ul className="list-disc bBpbXY">
                        <li>you will have 14 days from the effective date of termination to extract your Data from the
                          APP at no charge (Data Extraction Period).
                        </li>
                        <li>After the Data Extraction Period, you and other Users will have no further guaranteed access
                          to the APP or your Data.
                        </li>
                        <li>We cannot guarantee that we will always be able to recover your historical.</li>
                      </ul></li>
                    <li><label className="sc-gipzik kyeIcb">12.4 </label><strong>Data storage after the end of your
                      Subscription</strong> <br/>Data held in our systems is subject to back-up and archive processes. At
                      the end of your Subscription, we may retain copies of your Data in our archives. We may also
                      continue to store and use your Data in accordance with section 7.1. Our storage and use of that
                      Data remains subject to these Terms. You may request that we delete your Data by contacting us at
                      info@wewumbo.io.</li>
                    <li><label className="sc-gipzik kyeIcb">12.5 </label><strong>Survival.</strong> <br/> The following
                      provisions survive any termination or expiration of these Terms: Sections 6.3 (Restrictions), 7.3
                      (Your Indemnity), 8.1 (Third-Party Products), 9 (IP Rights in the APP and Feedback), 10 (How You
                      Can Cancel Subscription) and 11 (How We Can Cancel Subscription), 13 (Warranty Disclaimer), 14
                      (Liability and Indemnity), 16 (Dispute Resolution) and 18 (General Provisions).</li>
                  </ul>
                </li>
                <li>
                  <strong>Warranties and Disclaimer.</strong> <br/>
                  <ul className="sc-jDwBTQ efbqVU">
                    <li><label className="sc-gipzik kyeIcb">13.1 </label><strong>Our Warranties.</strong> We warrant,
                      for your only, that we use commercially reasonable efforts to prevent introduction of viruses,
                      Trojan horses, or similar harmful materials into the APP (but we are not responsible for harmful
                      materials submitted by you or End Users).
                    </li>
                    <li><label className="sc-gipzik kyeIcb">13.2 </label><strong>WARRANTY DISCLAIMER.</strong> EXCEPT AS
                      EXPRESSLY PROVIDED IN THIS PROVIDED IN THIS SECTION 13, THE APP, SUPPORT AND ADDITIONAL SERVICES
                      ARE PROVIDED “AS IS,” AND WE AND OUR SUPPLIERS EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND
                      REPRESENTATIONS OF ANY KIND, INCLUDING ANY WARRANTY OF NON- INFRINGEMENT, TITLE, FITNESS FOR A
                      PARTICULAR PURPOSE, FUNCTIONALITY OR MERCHANTABILITY, WHETHER EXPRESS, IMPLIED OR STATUTORY.
                      WITHOUT LIMITING OUR EXPRESS OBLIGATIONS IN THESE TERMS, WE DO NOT WARRANT THAT YOUR USE OF THE
                      APP WILL BE UNINTERRUPTED OR ERROR-FREE, THAT WE WILL REVIEW YOUR DATA FOR ACCURACY OR THAT WE
                      WILL PRESERVE OR MAINTAIN YOUR DATA WITHOUT LOSS. YOU UNDERSTAND THAT USE OF THE APP NECESSARILY
                      INVOLVES TRANSMISSION OF YOUR DATA OVER NETWORKS THAT WE DO NOT OWN, OPERATE OR CONTROL, AND WE
                      ARE NOT RESPONSIBLE FOR ANY OF YOUR DATA LOST, ALTERED, INTERCEPTED OR STORED ACROSS SUCH
                      NETWORKS. WE CANNOT GUARANTEE THAT OUR SECURITY PROCEDURES WILL BE ERROR-FREE, THAT TRANSMISSIONS
                      OF YOUR DATA WILL ALWAYS BE SECURE OR THAT UNAUTHORIZED THIRD PARTIES WILL NEVER BE ABLE TO DEFEAT
                      OUR SECURITY MEASURES OR THOSE OF OUR THIRD-PARTY SERVICE PROVIDERS. WE WILL NOT BE LIABLE FOR
                      ELAYS, INTERRUPTIONS, SERVICE FAILURES OR OTHER PROBLEMS INHERENT IN USE OF THE INTERNET AND
                      ELECTRONIC COMMUNICATIONS OR OTHER SYSTEMS OUTSIDE OUR REASONABLE CONTROL. YOU MAY HAVE OTHER
                      STATUTORY RIGHTS, BUT THE DURATION OF STATUTORILY REQUIRED WARRANTIES, IF ANY, WILL BE LIMITED TO
                      THE SHORTEST PERIOD PERMITTED BY LAW.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Liability and Indemnity</strong> <br/>
                  <ul className="sc-jDwBTQ efbqVU">
                    <li><label className="sc-gipzik kyeIcb">14.1 </label>Despite anything to the contrary, and to the
                      maximum extent permitted by law:
                      <ul className="sc-gPEVay bLuJGF">
                        <li><label className="sc-gipzik kyeIcb">14.1.1 </label>you agree to indemnify us for any
                          Liability we incur due to your breach of clause 7.2 of these Terms;
                        </li>
                        <li><label className="sc-gipzik kyeIcb">14.1.2 </label>Neither party will be liable for
                          Consequential Loss;
                        </li>
                        <li><label className="sc-gipzik kyeIcb">14.1.3 </label>each Party’s liability for any Liability
                          under these Terms will be reduced proportionately to the extent the relevant Liability was
                          caused or contributed to by the acts or omissions of the other party or any of that party’s
                          personnel (including a party’s End Users), including any failure by that party to mitigate its
                          losses; and
                        </li>
                        <li><label className="sc-gipzik kyeIcb">14.1.4 </label>our aggregate liability for any Liability
                          arising from or in connection with these Terms will be limited to us repaying you the amount
                          of the Fees paid by you to us during the Subscription Term.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Publicity Rights.</strong><br/>
                  We may identify you as a WE Wumbo customer in our promotional materials. We will promptly stop doing so upon your request.
                </li>
                <li>
                  <strong>Dispute Resolution.</strong><br/>
                  <ul className="sc-jDwBTQ efbqVU">
                    <li><label className="sc-gipzik kyeIcb">16.1 </label><strong>Informal Resolution.</strong> In the
                      event of any controversy or claim arising out of or relating to these Terms, the parties will
                      consult and negotiate with each other and, recognizing their mutual interests, attempt to reach a
                      solution satisfactory to both parties. If the parties do not reach settlement within a period of
                      sixty (60) days, either party may pursue relief as may be available in the local governing
                      jurisdiction. All negotiations pursuant to this Section 16.1 will be confidential and treated as
                      compromise and settlement negotiations for purposes of all rules and codes of evidence of
                      applicable legislation and jurisdictions.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Changes to these Terms.</strong><br/>
                  <ul className="sc-jDwBTQ efbqVU">
                    <li>We may modify the terms and conditions of these Terms (including Our Policies) from time to
                      time, with notice to you in accordance with Section 18.1 (Notices) or by posting the modified
                      Terms on our website. Together with notice, we will specify the effective date of the
                      modifications.
                    </li>
                    <li><label className="sc-gipzik kyeIcb">17.1 </label> Except as otherwise indicated below,
                      modifications to these Terms will take effect at the next renewal of your Subscription Term and
                      will automatically apply as of the renewal date unless you elect not to renew pursuant to Section
                      4.4 (Renewals). Notwithstanding the foregoing, in some cases (e.g., to address compliance with
                      Laws, or as necessary for new features) we may specify that such modifications become effective
                      during your then-current Subscription Term. If the effective date of such modifications is during
                      your then-current Subscription Term and you object to the modifications, then (as your exclusive
                      remedy) you may terminate your affected Contract upon notice to us, and we will refund you any
                      fees you have pre-paid for use of the APP for the terminated portion of the applicable
                      Subscription Term. To exercise this right, you must provide us with notice of your objection and
                      termination within thirty (30) days of us providing notice of the modifications. For the avoidance
                      of doubt, any Contract is subject to the version of these Terms in effect at the time of the
                      Contract.
                    </li>
                    <li><label className="sc-gipzik kyeIcb">17.2 </label> <strong>Our Policies.</strong> We may modify
                      Our Policies to take effect during your then-current Subscription Term in order to respond to
                      changes in our products, our business, or Laws. In this case, unless required by Laws, we agree
                      not to make modifications to Our Policies that, considered as a whole, would substantially
                      diminish our obligations during your then-current Subscription Term. Modifications to Our Policies
                      will take effect automatically as of the effective date specified for the updated policies.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>General Provisions.</strong><br/>
                  <ul className="sc-ckVGcZ imAKlb">
                    <li><label className="sc-kEYyzF inbxuf">18.1 </label><strong>Notices.</strong> Any notice under
                      these Terms must given in writing. We may provide notice to you through your Notification Email
                      Address, your account, or in-product notifications. You agree that any electronic communication
                      will satisfy any applicable legal communication requirements, including that such communications
                      be in writing. Any notice to you will be deemed given upon the first business day after we send
                      it. Your notices to us will be deemed given upon receipt.<br/>You can notify us by email at
                      <a
                        className="text-blue"
                        href="mailto:accounts@wewumbo.io"
                      > accounts@wewumbo.io</a>.
                    </li>
                    <li><label className="sc-kEYyzF inbxuf">18.2 </label><strong>Force Majeure.</strong> Neither party
                      will be the other for any delay or failure to perform any obligation under these Terms (except for
                      a failure to pay fees) if the delay or failure is due to events which are beyond the reasonable
                      control of such party, such as a strike, blockade, war, act of terrorism, riot, natural disaster,
                      failure or diminishment of power or telecommunications or data networks or services, or refusal of
                      a license by a government agency.
                    </li>
                    <li><label className="sc-kEYyzF inbxuf">18.3 </label><strong>Assignment.</strong> You may not assign
                      or transfer these Terms without our prior written consent. As an exception to the foregoing, you
                      may assign these Terms in their entirety (including all Orders) to your successor resulting from a
                      merger, acquisition, or sale of all or substantially all of your assets or voting securities,
                      provided that you provide us with prompt written notice of the assignment and the assignee agrees
                      in writing to assume all of your obligations under these Terms. Any attempt by you to transfer or
                      assign these Terms except as expressly authorized above will be null and void. We may assign our
                      rights and obligations under these Terms (in whole or in part) without your consent. We may also
                      permit our Affiliates, agents, and contractors to exercise our rights or perform our obligations
                      under these Terms, in which case we will remain responsible for their compliance with these Terms.
                      Subject to the foregoing, these Terms will inure to the parties’ permitted successors and assigns.
                    </li>
                    <li><label className="sc-kEYyzF inbxuf">18.4 </label> <strong>Entire Agreement.</strong> These Terms
                      are the Terms are the entire agreement between you and us relating to the APP and any other
                      subject matter covered by these Terms and supersede all prior or contemporaneous oral or written
                      communications, proposals and representations between you and us with respect to the APP or any
                      other subject matter covered by these Terms. No provision of any purchase order or other business
                      form employed by you will supersede or supplement the terms and conditions of these Terms, and any
                      such document relating to these Terms will be for administrative purposes only and will have no
                      legal effect.
                    </li>
                    <li><label className="sc-kEYyzF inbxuf">18.5 </label> <strong>Waivers; Modifications.</strong> No
                      failure or failure or delay by the injured party to these Terms in exercising any right, power or
                      privilege hereunder will operate as a waiver thereof, nor will any single or partial exercise
                      thereof preclude any other or further exercise thereof or the exercise of any right, power, or
                      privilege hereunder at law or equity. Except as set forth in Section 17 (Changes to these Terms),
                      any amendments or modifications to these Terms must be executed in writing by an authorized
                      representative of each party.
                    </li>
                    <li><label className="sc-kEYyzF inbxuf">18.6 </label><strong>Governing Law:</strong> These Terms are
                      governed by the laws of Victoria. Each Party irrevocably and unconditionally submits to the
                      exclusive jurisdiction of the courts operating in Victoria and any courts entitled to hear appeals
                      from those courts and waives any right to object to proceedings being brought in those courts.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Definitions.</strong><br/>
                  <ul className="sc-ckVGcZ imAKlb">
                    <li>Certain capitalized terms are defined in this Section 19, and others are defined contextually in
                      these Terms.
                    </li>
                    <li>“Additional Services” means In-Home Services, Wumbo Club, and or other services related to the
                      APP we provide to you, as identified in the Contract. For the avoidance of doubt, Additional
                      Services do not include the standard level of support included in your subscription.
                    </li>
                    <li>“Administrators” mean the personnel designated by you who administer the APP to End Users on
                      your behalf.
                    </li>
                    <li>“Affiliate” means an entity which, directly or indirectly, owns or controls, is owned or is
                      controlled by or is under common ownership or control with a party, where “control” means the
                      power to direct the management or affairs of an entity, and “ownership” means the beneficial
                      ownership of greater than 50% of the voting equity securities or other equivalent voting interests
                      of the entity.
                    </li>
                    <li>“APP” means our cloud-based solution including any software we provide as part of the APP.</li>
                    <li>“Consequential Loss” includes any consequential loss, indirect loss, real or anticipated loss of
                      profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity,
                      loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under
                      statute, contract, equity, tort (including negligence), indemnity or otherwise.
                    </li>
                    <li>“Documentation” means our standard published documentation for the APP.</li>
                    <li>“End User” means an individual you or an Affiliate permit or invites to use the APP. For the
                      avoidance of doubt; (a) individuals invited by your End Users, (b) individuals under managed
                      accounts, and (c) individuals interacting with the APP as your customer are also considered End
                      Users.
                    </li>
                    <li>“End User Account” means an account established by you or an End User to enable the End User to
                      use or access the APP.
                    </li>
                    <li>“Feedback” means comments, questions, ideas, suggestions, or other feedback relating to the APP,
                      Support or Additional Services.
                    </li>
                    <li>“Notification Email Address” means the email address(s) you used to register for the APP
                      account. It is your responsibility to keep your email address(s) valid and current so that we can
                      send notices, statements, and other information to you.
                    </li>
                    <li>“Contract” means the agreement from you to become a subscriber of the APP. This will be
                      accessible in the APP. As applicable, the Contract will identify: (i) Subscription Term, Annual
                      Fee, Billing Period, (ii) storage capacity or limits, or other scope of use parameters.
                    </li>
                    <li>“Our Policies” means these Terms of Service and our Privacy Policy, and (unless specified) any
                      other policies or terms referenced in these Terms.
                    </li>
                    <li>“Subscription Term” means your permitted subscription period for the APP, as set forth in the
                      applicable Contract.
                    </li>
                    <li>“Support” means support for the APP, as further described in the Service Level Agreement
                      (SLA).
                    </li>
                    <li>“Your Data” means any data, content, video, images, or other materials of any type that you
                      (including any of your End Users) submit to the APP. In this context, “submit” (and any similar
                      term) includes submitting, uploading, transmitting, or otherwise making available Your Data to or
                      through the APP.
                    </li>
                    <li>“Your Materials” means your materials, systems, personnel, or other resources.</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </LayoutDetail>
  )
}

export const Head = () => <Seo title="Term & Conditions" />

export default TermConditionsPage