/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "“WE Wumbo gets digital with its mortgage broking service by partnering with Effi.”"), "\n", React.createElement(_components.p, null, "The RBA have increased interest rates to a new 10-year high leaving borrowers concerned about their financial status and further possible rate increases in the New Year. Given that 70% of all Australian home loans are settled by mortgage brokers, we recognised the value of partnering with Effi Technologies (Effi) to provide our communities an easy and convenient channel to highly rated local mortgage brokers for home loans and refinancing."), "\n", React.createElement(_components.p, null, "This is another WE Wumbo initiative that provides real value creation and enriched resident experiences."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
