import * as React from 'react'
import LayoutDetail from '../../components/layout-detail'
import Seo from '../../components/seo'

const PrivacyPolicyPage = ({ location }) => {
  return (
    <LayoutDetail pageTitle="Privacy Policy" location={location}>
      <div className="mx-auto">
        <div className="px-4 md:mt-10 text-black md:px-[80px] xl:px-[180px] 2xl:px-auto">
          <div className="md:max-w-[1080px] md:mx-auto py-10">
            <h2 className="text-[24px] leading-[24px] bold-text mb-[24px]">Privacy Policy</h2>
            <div className="text-black text-sm md:text-base">
              <p className="sc-iAyFgw kTdXen">WE Wumbo Pty Ltd (ABN 58 615 373 610) (“we”, “our” or “us”), understands
                that protecting your personal information is important. This Privacy Policy sets out our commitment to
                protecting the privacy of personal information provided to us, or otherwise collected by us. We are a
                cloud-based building management system and through our platform (<strong>WE Wumbo platform</strong>). We
                provide resident management capabilities to strata managers and a platform for residents to interact
                with each other and with management, where residents may book building facilities, lodge and track
                maintenance requests, store and share documents, engage third party services, and make general enquiries
                (<strong>Services</strong>).</p>
              <p className="bold-text mt-10">The information we collect:</p>
              <div><strong>Personal information:</strong> is information or an opinion, whether true or not and whether
                recorded in a material form or not, about an individual who is identified or reasonably identifiable.
                The types of personal information we may collect about you include:
                <ul className="list-disc gmKbQu">
                  <li><strong>Identity Data</strong> including first name, middle name, last name, maiden name, title,
                    date of birth, gender, job title, photographic identification, marital status, pronouns, your
                    company or business name, ABN, and images of you.
                  </li>
                  <li><strong>Contact Data</strong> including billing address, delivery address, email address, fax
                    number and telephone numbers.
                  </li>
                  <li><strong>Financial Data</strong> including bank account and payment card details.</li>
                  <li><strong>Background Verification Data</strong> including your driver licence number, photographic
                    identification or other details requested as part of our onboarding process to comply with our due
                    diligence obligations and related ongoing monitoring commitments.
                  </li>
                  <li><strong>Transaction Data</strong> including details about payments to you from us and from you to
                    us and other details of products and services you have purchased from us, or we have purchased from
                    you.
                  </li>
                  <li><strong>Technical and Usage Data</strong> including internet protocol (IP) address, your login
                    data, your browser session and geo-location data, device and network information, statistics on page
                    views and sessions, acquisition sources, search queries made by you and/or browsing behaviour,
                    information about your access and use of the WE Wumbo platform, including through the use of
                    Internet cookies on our website and your communications within WE Wumbo platform.
                  </li>
                  <li><strong>Profile Data</strong> including your username and password for WE Wumbo platform, profile
                    picture, purchases, or orders you have made with us, third party services you have engaged through
                    WE Wumbo platform, support requests you have made, content you post, communications you send and
                    receive through our platform, information you have shared with our social media platforms, your
                    interests, preferences, feedback, and survey responses.
                  </li>
                  <li><strong>Interaction Data</strong> including information you provide to us when you participate in
                    any interactive features of our Services, including surveys, promotions, activities, or events.
                  </li>
                  <li><strong>Marketing and Communications Data</strong> including your preferences in receiving
                    marketing from us and our third parties and your communication preferences.
                  </li>
                  <li><strong>Sensitive information</strong> is a sub-set of personal information that is given a higher
                    level of protection. Sensitive information means information relating to your racial or ethnic
                    origin, political opinions, religion, trade union or other professional associations or memberships,
                    philosophical beliefs, sexual orientation or practices, criminal records, health information or
                    biometric information. We do not actively request sensitive information about you. If at any time we
                    need to collect sensitive information about you, unless otherwise permitted by law, we will first
                    obtain your consent and we will only use it as required or authorized by law.
                  </li>
                </ul>
              </div>
              <p className="sc-dnqmqq hxxsYF">How we collect personal information</p>
              <div>We collect personal information in a variety of ways, including:
                <ul className="list-disc gmKbQu">
                  <li><strong>Directly:</strong> We collect personal information which you directly provide to us,
                    including when a strata manager creates your account and requests your name and email, via email,
                    face to face or over the telephone.
                  </li>
                  <li><strong>Indirectly:</strong> We may collect personal information which you indirectly provide to
                    us while interacting with us, such as in emails, over the telephone and in your enquiries via WE
                    Wumbo platform.
                  </li>
                  <li><strong>From third parties:</strong> We collect personal information from third parties, such as
                    third-party service providers integrated into the WE Wumbo platform.
                  </li>
                </ul>
              </div>
              <p className="bold-text mt-10">Why we collect, hold, use, and disclose personal information</p>
              <div>We have set out below, in a table format, a description of the purposes for which we plan to collect,
                hold, use, and disclose your personal information.
                <table className="sc-hSdWYo bIIVfX">
                  <thead>
                  <tr>
                    <td>Purpose of use / disclosure</td>
                    <td>Type of Personal Information</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>To verify your identity and enable you to access and use the WE Wumbo platform, including to
                      provide you with a login.
                    </td>
                    <td>
                      <ul className="sc-eHgmQL list-disc">
                        <li>Identity Data</li>
                        <li>Contact Data</li>
                        <li>Profile Data</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>To provide our Services to you, including to notify you of delivered parcels, book building
                      facilities, connect you to third party services (e.g., home cleaning, end of lease cleaning and
                      removals), manage your car spaces and guest access.
                    </td>
                    <td>
                      <ul className="sc-eHgmQL list-disc">
                        <li>Identity Data</li>
                        <li>Contact Data</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>To contact and communicate with you about our Services including in response to any support
                      requests you lodge with us (e.g., maintenance, defects, home cleaning etc), any building updates
                      or news about new features, events, products or services or other enquiries you make with us.
                    </td>
                    <td>
                      <ul className="sc-eHgmQL list-disc">
                        <li>Identity Data</li>
                        <li>Contact Data</li>
                        <li>Profile Data</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>To connect you with other building residents so you can communicate with them</td>
                    <td>
                      <ul className="sc-eHgmQL list-disc">
                        <li>Identity Data</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>For internal record keeping, administrative, creation of a client base, audits, reporting
                      requirements, invoicing, and billing purposes.
                    </td>
                    <td>
                      <ul className="sc-eHgmQL list-disc">
                        <li>Identity Data</li>
                        <li>Contact Data</li>
                        <li>Profile Data</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>For analytics, market research and business development, including to operate and improve our
                      Services and associated applications and providing the ratings and reviews you submit to third
                      parties.
                    </td>
                    <td>
                      <ul className="sc-eHgmQL list-disc">
                        <li>Identity Data</li>
                        <li>Technical and Usage Data</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>To provide you with technical support or training.</td>
                    <td>
                      <ul className="sc-eHgmQL list-disc">
                        <li>Identity Data</li>
                        <li>Profile Data</li>
                        <li>Technical and Usage Data</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>For advertising and marketing, including to send you promotional information about our Services
                      and information that we consider may be of interest to you.
                    </td>
                    <td>
                      <ul className="sc-eHgmQL list-disc">
                        <li>Identity Data</li>
                        <li>Contact Data</li>
                        <li>Technical and Usage Data</li>
                        <li>Profile Data</li>
                        <li>Marketing and Communications Data</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>To comply with our legal obligations or if otherwise required or authorised by law.</td>
                    <td>
                      <ul className="sc-eHgmQL list-disc">
                        <li>Any relevant Personal Information</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Sensitive information:</strong> We only collect, hold, use, and disclose sensitive
                      information for the following purposes:
                      <ul className="sc-eHgmQL list-disc">
                        <li>any purposes you consent to.</li>
                      </ul>
                    </td>
                    <td>
                      <ul className="sc-eHgmQL list-disc">
                        <li>Sensitive Information</li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <p className="bold-text mt-10">Our disclosures of personal information to third parties</p>
              <div>We may disclose personal information to:
                <ul className="list-disc gmKbQu">
                  <li>any third-party service providers integrated into our WE Wumbo platform such as Whizz (cleaning
                    services);
                  </li>
                  <li>our employees, contractors and/or related entities;</li>
                  <li>IT service providers, data storage, webhosting, and server providers such as AWS;</li>
                  <li>marketing or advertising providers;</li>
                  <li>payment systems operators such as Stripe;</li>
                  <li>our existing or potential agents or business partners;</li>
                  <li>anyone to whom our business or assets (or any part of them) are, or may (in good faith) be,
                    transferred;
                  </li>
                  <li>courts, tribunals, regulatory authorities, and law enforcement officers, as required or authorised
                    by law, in connection with any actual or prospective legal proceedings, or in order to establish,
                    exercise or defend our legal rights; and
                  </li>
                  <li>any other third parties as required or permitted by law, such as where we receive a subpoen</li>
                </ul>
                <br/>
              </div>
              <p className="bold-text mt-10">Overseas disclosure</p>
              <p className="sc-iAyFgw mt-5">While we store personal information in Australia, where we disclose your
                personal information to the third parties listed above, these third parties may store, transfer or
                access personal information outside of Australia, including but not limited to, Japan and England. We
                will only disclose your personal information overseas in accordance with the Australian Privacy
                Principles.</p>
              <p className="bold-text mt-10">Your rights and controlling your personal information</p>
              <p className="sc-iAyFgw mt-5"><strong>Your choice:</strong> Please read this Privacy Policy carefully.
                If you provide personal information to us, you understand we will collect, hold, use, and disclose your
                personal information in accordance with this Privacy Policy. You do not have to provide personal
                information to us, however, if you do not, it may affect our ability to provide our Services to you and
                your use of our Services.<br/><br/><strong>Information from third parties:</strong> If we receive personal
                  information about you from a third party, we will protect it as set out in this Privacy Policy. If you
                  are a third party providing personal information about somebody else, you represent and warrant that
                  you have such person’s consent to provide the personal information to us.<br/><br/><strong>Restrict and
                    unsubscribe:</strong> To object to processing for direct direct marketing/unsubscribe from our email
                    database or opt-out of communications (including marketing communications), please contact us using
                    the details below or opt-out using the opt-out facilities provided in the
                    communication.<br/><br/><strong>Correction:</strong> If you believe that any information, we hold about
                      you is inaccurate, out of date, incomplete, irrelevant, or misleading, please contact us using the
                      details below. We will take reasonable steps to promptly correct any information found to be
                      inaccurate, out of date, incomplete, irrelevant, or misleading. Please note, in some situations,
                      we may be legally permitted to not correct your personal information. If we cannot correct your
                      information, we will advise you as soon as reasonably possible and provide you with the reasons
                      for our refusal and any mechanism available to complain about the
                      refusal.<br/><br/><strong>Complaints:</strong> If you wish to make a complaint, please contact us using
                        the details below and provide us with full details of the complaint. We will promptly
                        investigate your complaint and respond to you, in writing, setting out the outcome of our
                        investigation and the steps we will take in response to your complaint.</p>
              <p className="bold-text mt-10">Storage and security</p>
              <p className="sc-iAyFgw kTdXen mt-5">We are committed to ensuring that the personal information we collect is
                secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical,
                electronic, and managerial procedures, to safeguard and secure personal information and protect it from
                misuse, interference, loss and unauthorized access, modification, and disclosure. <br/>We utilise and
                  host data on Amazon AWS Cloud services. Amazon is one of the largest, globally renowned cloud service
                  providers in the world, with high level security. Amazon servers are fully monitored with hardware
                  firewall systems for the protection of your data and information. Amazon server provider does not have
                  access to your personal information and is limited to only providing a hosting and service to us for
                  Australian properties. Amazon servers are located in Sydney, Australia. If you would like to read more
                  information on Amazon and how we store data, it can be found here: aws.amazon.com <br/>While we are
                    committed to security, we cannot guarantee the security of any information that is transmitted to or
                    by us over the Internet. The transmission and exchange of information is carried out at your own
                    risk.</p>
              <p className="bold-text mt-10">Cookies</p>
              <p className="sc-iAyFgw mt-5">We may use cookies on our website from time to time. Cookies are text
                files placed in your computer’s browser to store your preferences. Cookies, by themselves, do not tell
                us your email address or other personally identifiable information. However, they do recognise you when
                you return to our online website and allow third parties, such as Google and Facebook, to cause our
                advertisements to appear on your social media and online media feeds as part of our retargeting
                campaigns. If and when you choose to provide our online website with personal information, this
                information may be linked to the data stored in the cookie.<br/>We may use cookies for a number of
                  purposes, such as to prevent the same ad from displaying repeatedly or to display ads that are likely
                  to be more relevant to your interests. We may use a set of cookies, including conversion cookies, to
                  collect information and report website usage statistics without personally identifying individual
                  visitors. The main purpose of conversion cookies is to help determine how many times people who click
                  on ads filled out a guest card or signed a lease; conversion cookies are not used for interest-based
                  ad targeting and persist for a limited time only. Conversion cookie data may also be used in
                  combination with your Google account to link conversion events across different devices you use. Only
                  anonymous conversion data gathered from these cookies is shared with advertisers.<br/>You can block
                    cookies by activating the setting on your browser that allows you to refuse the setting of all or
                    some cookies. However, if you use your browser settings to block all cookies (including essential
                    cookies) you may not be able to access all or parts of our website.</p>
              <p className="bold-text mt-10">Use of Google API Services (this section applies to personal information
                accessed through Google API Services)</p>
              <div>
                <br/>
                We use Google’s Application Programming Interface (API) Service to access information from Google Maps. <br/>
                Our use of information received from Google APIs will adhere to the
                <a className="text-blue" href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank"> Google API Services User Data Policy</a>
                , including the Limited Use requirements (set out in the Google API Services User Data Policy). <br/>
                We limit our use of the personal information that we collect to providing or improving our Services. We do not use the personal information for any other purposes. <br/>
                We only transfer the personal information to others if it is necessary to provide or improve our Services or as necessary to comply with applicable law or as part of a merger, acquisition or sale of assets where we notify you of this. <br/>
                We do not use or transfer the personal information for serving ads, including retargeting, personalised or interest based advertising. <br/>
                We don’t allow humans to read the personal information unless <br/>
              <ul className="list-disc">
                <li>we have first received your consent to view specific messages, files or other data;</li>
                <li>it is necessary for security purposes (such as investigating a bug or abuse);</li>
                <li>our use is limited to internal operations and the personal information (including derivations) have been aggregated and anonymised.</li>
              </ul>
                <br/>
                These restrictions apply to the raw data we obtain from the restricted scopes and data that is aggregated, anonymised or derived from them. We have policies and procedures in place setting out appropriate safeguards for protecting your personal information obtained with the Google APIs. We will also ensure that our employees, agents, contractors and successors comply with the Google API Services Data Policy.
              </div>
              <p className="bold-text mt-10">Amendments</p>
              <p className="sc-iAyFgw mt-5">We may, at any time and at our discretion, vary this Privacy Policy by
                publishing the amended Privacy Policy on our website and WE Wumbo platform. We recommend you check our
                website and WE Wumbo platform regularly to ensure you are aware of our current Privacy Policy.<br/><br/>For
                  any questions or notices, please contact us at:<br/>WE Wumbo Pty Ltd (ABN 58 615 373 610)<br/>Email:
                <a
                  className="text-blue"
                  href="mailto:info@wewumbo.io">info@wewumbo.io </a>
                <br/><br/>Last update: 02/09/2022<br/>©
                <a
                  className="text-blue"
                  href="https://legalvision.com.au/" target="_blank" rel="noopener noreferrer"> LegalVision ILP Pty
                  Ltd</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </LayoutDetail>
  )
}

export const Head = () => <Seo title="Privacy Policy" />

export default PrivacyPolicyPage
