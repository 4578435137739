import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { StaticImage } from 'gatsby-plugin-image'
import { isMobileOnly } from "react-device-detect"
import Seo from "../components/seo"
import Layout from "../components/layout"
import ReadMore from "../components/read-more"

import today from "../images/index/today.png"
import todayMb from "../images/index/today-mb.png"
import {
  section1,
  section2,
  section3,
  section4,
  imageBackground,
  textBackground,
} from "../styles/index.module.scss"

import smartGifMb from "../images/index/Mobile_Winning-Formula.gif"
import smartGif from "../images/index/Web_6_Winning Formula.gif"

import gameGifMb from "../images/index/Mobile_Game-Changer.gif"
import gameGif from "../images/index/Web_2_Game Changer.gif"

import buildingGif from "../images/index/Build-to-Rent.gif"

const IndexPage = ({location}) => {
  const [width, setWidth] = useState(0)
  const [offset, setOffset] = useState(0)

  const [gameOffset, setGameOffset] = useState(1769)
  const [gameNextRefOffset, setGameNextRefOffset] = useState(2632)

  const [smartOffset, setSmartOffset] = useState(5276)
  const [smartNextRefOffset, setSmartNextRefOffset] = useState(7038)

  // const [communityOffset, setCommunityOffset] = useState(8515)
  // const [socialOffset, setSocialOffset] = useState(10291)

  const gameRef = useRef();
  const gameNextRef = useRef();

  const smartRef = useRef();
  const smartNextRef = useRef();

  const communityRef = useRef();
  const socialRef = useRef();

  useEffect(() => {
    const onScroll = () => {
      setWidth(window.innerWidth)
      setOffset(window.pageYOffset)

      setGameOffset(gameRef.current.offsetTop)
      setGameNextRefOffset(gameNextRef.current.offsetTop)

      setSmartOffset(smartRef.current.offsetTop)
      setSmartNextRefOffset(smartNextRef.current.offsetTop)

      // setCommunityOffset(communityRef.current.offsetTop)
      // setSocialOffset(socialRef.current.offsetTop)
    }
    window.removeEventListener("scroll", onScroll)
    window.addEventListener("scroll", onScroll, { passive: true })
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  const gameClassEffect = () => {
    // console.log(offset)
    // console.log(gameOffset)
    let className = '';
    if (width >= 768 && offset > 1490 && gameOffset - offset < 0) {
      className = 'fixed__social';
    }

    if (width < 768 && offset > 1320 && gameOffset - offset < 20) {
      className = 'fixed__social';
    }

    return className;
  };

  const smartClassEffect = () => {
    // console.log(offset)
    // console.log(smartOffset)
    let className = '';
    if (width >= 768 && offset > 4112 && smartOffset - offset < 0) {
      className = 'fixed__social';
    }

    if (width < 768 && offset > 4185 && smartOffset - offset < 0) {
      className = 'fixed__social';
    }

    return className;
  };

  // const communityClassEffect = () => {
  //   // console.log(offset)
  //   // console.log(communityOffset)
  //   let className = '';
  //   if (!isMobile && offset > 7520 && communityOffset - offset < 0) {
  //     className = 'fixed__social';
  //   }
  //
  //   if (isMobile && offset > 6420 && communityOffset - offset < 0) {
  //     className = 'fixed__social';
  //   }
  //
  //   return className;
  // };


  return (
    <Layout pageTitle="Home page" location={location}>
      <section className={section1}>
        <img className="w-hidden max-w-[300px]" src={todayMb} alt="Otter dancing with a fish" />
        <img className="mb-hidden w-full mx-auto" src={today} alt="Otter dancing with a fish" />
      </section>
      <section className={section2}>
        {/*<div className="h-[375px] 2xl:h-[450px] section-1-image-background" />*/}
        <div className="bg-white opacity-40">
          <div className="w-hidden">
            <StaticImage
              alt="drop-off-collect.png"
              className=""
              src="../images/index/australia-108-melbourne-48-mb.png"
            />
          </div>
          <div className="mb-hidden">
            <StaticImage
              alt="drop-off-collect.png"
              className="h-[406px] 2xl:h-full"
              src="../images/index/australia-108-melbourne-48.png"
            />
          </div>
        </div>
        <div className="absolute inset-0 m-auto overflow-auto w-full h-fit">
          <div className="overflow-y-hidden">
            <p className={textBackground}>New Expectations.</p>
            <p className={textBackground}>New Benchmark.</p>
          </div>
        </div>
      </section>
      <section className={section3}>
        <div className="py-[140px] text-sm md:text-base">
          <p>
         WE Wumbo shifts the focus from assets to people as lifestyle building management is now the expectation and new benchmark for vertical living environments.&nbsp;
            <span className="bold-text">
            Be creative, explore, and abandon certain legacy ways, to accelerate the path forward. Now more than ever is the time to be smart and emerge as the beacon of change.
          </span>
        </p>
        </div>
      </section>
      <div
        ref={gameRef}
        className={gameClassEffect()}
        style={{opacity: gameNextRefOffset - offset > 0 ? 1 : 0}}
      >
        <section className="relative">
          <img className="w-hidden pt-[125px]" src={gameGifMb} alt="Otter dancing with a fish" />
          <img className="mb-hidden md:mx-auto w-full md:max-h-[700px] 2xl:max-h-[900px]" src={gameGif} alt="Otter dancing with a fish" />
        </section>
      </div>
      <div className="fixed__spacer">
        <div className="fixed__spacer-inner" style={{height: isMobileOnly ? '406' : '900px'}}></div>
      </div>
      <div className="section-relative mb-[100px] md:mb-0" ref={gameNextRef}>
        <section className={section2}>
          {/*<div className="h-[375px] 2xl:h-[450px] section-2-image-background" />*/}
          <div className="w-hidden">
            <StaticImage
              alt="drop-off-collect.png"
              className=""
              src="../images/index/Building-Portfilio-mb.png"
            />
          </div>
          <div className="mb-hidden">
            <StaticImage
              alt="drop-off-collect.png"
              className="h-[406px] 2xl:h-full"
              src="../images/index/Building-Portfilio.png"
            />
          </div>
          <div className="absolute inset-0 m-auto overflow-auto w-full h-fit mb-hidden">
            <div className="overflow-y-hidden">
              <p className="font-bold text-[30px] md:text-[90px] justify-center text-center uppercase head-text text-white">Let’s Get Digital. </p>
            </div>
          </div>
        </section>
        <section className={section3}>
          <div className="py-[80px] pd:my-0 text-sm md:text-base">
            <span className="md:leading-[19px]">
            Its long been said that three things matter in real estate: location, location, location. However, based on trends that have been reshaping other industries,
          competition in real estate has spread to another area: <span className="bold-text text-[19px]">resident / customer experience.</span> <br/>
          </span><br/>
            <span className="pt-4 md:leading-[19px]">
            We believe that real estate is no longer about delivering four walls to a resident. Instead it is about providing, activating and merchandising programs within those four walls so
          that our residents have a more productive, more personalised and more rewarding experience – the <i>fifth wall.</i>
          </span>
          </div>
        </section>
        <section className={section4}>
          <div className="my-20 text-white md:mx-[-36px] md:mx-auto block">
            <div className="flex justify-center my-10">
              <h1 className="font-bold text-[30px] md:text-[90px] justify-center text-center uppercase head-text">WE Listen, we learn.</h1>
            </div>
            <div className="mb-[180px]">
              <div className="flex flex-col md:flex-row">
                <div className="flex-1">
                  <div className="w-auto mx-5 my-4 md:mx-au md:my-auto">
                    <p className="text-[19px] md:text-3xl text-white bold-text">Building Managers.</p>
                    <div className="wrapper-progress">
                      <div className="inner-progress">
                        <div className="full-progress-left width-99" />
                        <span className="real-progress">99%</span>
                      </div>
                      <p className="text-sm md:text-base text-white py-3.5">Want an effective way to communicate with residents</p>
                    </div>
                  </div>
                  <div className="w-auto mx-5 my-4 md:mx-au md:my-auto">
                    <div className="wrapper-progress">
                      <div className="inner-progress">
                        <div className="full-progress-left w-1/2" />
                        <span className="real-progress">50%</span>
                      </div>
                      <p className="text-sm md:text-base text-white py-3.5">Want a more efficient drop off / Collect system</p>
                    </div>
                  </div>
                  <div className="w-auto mx-5 my-4 md:mx-au md:my-auto">
                    <div className="wrapper-progress">
                      <div className="inner-progress">
                        <div className="full-progress-left w-4/5" />
                        <span className="real-progress">80%</span>
                      </div>
                      <p className="text-sm md:text-base text-white py-3.5">Want software to improve their business</p>
                    </div>
                  </div>
                  <div className="w-auto mx-5 my-4 md:mx-au md:my-auto">
                    <div className="wrapper-progress">
                      <div className="inner-progress">
                        <div className="full-progress-left w-1/2" />
                        <span className="real-progress">50%</span>
                      </div>
                      <p className="text-sm md:text-base text-white py-3.5">Acknowledge they are not tech savvy</p>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="w-auto mx-5 my-4 md:mx-au md:my-auto">
                    <p className="text-[19px] md:text-3xl text-white bold-text">Residents.</p>
                    <div className="wrapper-progress">
                      <div className="inner-progress">
                        <div className="full-progress-right w-4/5" />
                        <span className="real-progress">80%</span>
                      </div>
                      <p className="text-sm md:text-base text-white py-3.5">Prefer using a custom portal rather than Facebook</p>
                    </div>
                  </div>
                  <div className="w-auto mx-5 my-4 md:mx-au md:my-auto">
                    <div className="wrapper-progress">
                      <div className="inner-progress">
                        <div className="full-progress-right w-4/5" />
                        <span className="real-progress">80%</span>
                      </div>
                      <p className="text-sm md:text-base text-white py-3.5">Want a portal that talks directly to them</p>
                    </div>
                  </div>
                  <div className="w-auto mx-5 my-4 md:mx-au md:my-auto">
                    <div className="wrapper-progress">
                      <div className="inner-progress">
                        <div className="full-progress-right w-3/5" />
                        <span className="real-progress">60%</span>
                      </div>
                      <p className="text-sm md:text-base text-white py-3.5">Want to connect to their neighbours</p>
                    </div>
                  </div>
                  <div className="w-auto mx-5 my-4 md:mx-au md:my-auto">
                    <div className="wrapper-progress">
                      <div className="inner-progress">
                        <div className="full-progress-right width-90" />
                        <span className="real-progress">90%</span>
                      </div>
                      <p className="text-sm md:text-base text-white py-3.5">Want to book facilities from their home</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div
        ref={smartRef}
        className={smartClassEffect()}
        style={{opacity: offset > 2700 && smartNextRefOffset - offset > 0 ? 1 : 0}}
      >
        <section className="table md:mx-auto">
          <img className="w-hidden pt-[125px]" src={smartGifMb} alt="Otter dancing with a fish" />
          <img className="mb-hidden md:mx-auto w-full md:max-h-[700px] 2xl:max-h-[900px]" src={smartGif} alt="Otter dancing with a fish" />
        </section>
      </div>
      <div className="fixed__spacer">
        <div className="fixed__spacer-inner" style={{height: isMobileOnly ? '406' : '900px'}}></div>
      </div>
      <div className="section-relative" ref={smartNextRef}>
        <section className="md:my-auto">
          <div className="flex flex-col md:flex-row md:h-[900px] self-content">
            <div className="md:w-5/12 mt-20 md:my-auto mx-auto text-center">
              <div className="max-w-[170px] md:max-w-none text-[30px] leading-[30px] md:text-[90px] md:leading-[90px] font-bold text-white uppercase head-text">All in One Platform. </div>
            </div>
            <div className="md:w-7/12 my-10 md:my-auto md:max-h-[750px]">
              <div className="w-hidden relative">
                <StaticImage
                  alt="all in one platform mb"
                  className={imageBackground}
                  src="../images/index/Web_7_All in One Platform.png"
                />
              </div>
              <div className="mb-hidden relative">
                <StaticImage
                  alt="all in one platform"
                  className={imageBackground}
                  src="../images/index/Web_7_All in One Platform.png"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="my-10 md:my-40 text-white md:px-[80px] xl:px-[180px] 2xl:px-auto">
          <div className="flex justify-center mb-[14px] md:mb-10">
            <p className="font-bold text-[30px] leading-[30px] md:text-[90px] md:leading-[90px] justify-center text-center uppercase head-text">Resident Features.</p>
          </div>
          <div className="text-center text-white">
            <div className="pb-10 px-4 text-sm md:text-base md:max-w-[1080px] md:mx-auto mb-hidden">
              WE Wumbo has been designed for resident convenience and engagement with the community both inside and outside.
              With ease, resident can book a facility, parcel collection, report an issue to management, and connect with their neighbours.
            </div>
            <div className="pb-10 px-4 text-sm md:text-base md:max-w-[1080px] md:mx-auto w-hidden">
              <ReadMore limit={213} text={`WE Wumbo has been designed for resident convenience and engagement with the community both inside and outside. 
            With ease, resident can book a facility, parcel collection, report an issue to management, and connect with their neighbours.`} />
            </div>
          </div>
          <div className="container mx-auto md:mb-[180px] px-4 text-white">
            <div className="overflow-x-auto md:overflow-x-visible md:max-w-[1080px] md:mx-auto">
              <div className="flex flex-nowrap md:grid md:grid-cols-4 gap-5">
                <div className="w-2/5 md:w-full flex-none text-center">
                  <StaticImage
                    alt="drop-off-collect.png"
                    className={imageBackground}
                    src="../images/index/drop-off-collect.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">Drop Off & Collect</h2>
                </div>
                <div className="w-2/5 md:w-full flex-none text-center">
                  <StaticImage
                    alt="my-documents.png"
                    className={imageBackground}
                    src="../images/index/my-documents.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">My Documents</h2>
                </div>
                <div className="w-2/5 md:w-full flex-none text-center">
                  <StaticImage
                    alt="report-requests.png"
                    className={imageBackground}
                    src="../images/index/report-requests.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">Report & Requests</h2>
                </div>
                <div className="w-2/5 md:w-full flex-none text-center">
                  <StaticImage
                    alt="defect-reports.png"
                    className={imageBackground}
                    src="../images/index/defect-reports.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">Defects Reports</h2>
                </div>
                <div className="w-2/5 md:w-full flex-none text-center">
                  <StaticImage
                    alt="what's-on.png"
                    className={imageBackground}
                    src="../images/index/what's-on.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">What’s On</h2>
                </div>
                <div className="w-2/5 md:w-full flex-none text-center">
                  <StaticImage
                    alt="facility-bookings.png"
                    className={imageBackground}
                    src="../images/index/facility-bookings.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">Facility Bookings</h2>
                </div>
                <div className="w-2/5 md:w-full flex-none text-center">
                  <StaticImage
                    alt="marketplace.png"
                    className={imageBackground}
                    src="../images/index/marketplace.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">Marketplace</h2>
                </div>
                <div className="w-2/5 md:w-full flex-none text-center">
                  <StaticImage
                    alt="in-home-services.png"
                    className={imageBackground}
                    src="../images/index/in-home-services.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">In-Home Services</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="text-white md:px-[80px] xl:px-[180px] 2xl:px-auto">
          <div className="flex justify-center mt-20 mb-[14px] md:mb-10">
            <p className="font-bold text-[30px] leading-[30px] md:text-[90px] md:leading-[90px] justify-center text-center uppercase head-text">Operations Features.</p>
          </div>
          <div>
            <div className="text-center mx-auto md:max-w-[1080px] pb-10 text-sm md:text-base mb-hidden">
              We listened, we learned, and have designed our platform to reduce building management and concierge time and energies with real-time resident communications, maintenance tasks, parcel collection, facility bookings, access & security, asset management and car space management to name a few… And the benefit of our cloud base platform it can be accessed anytime, anywhere!
            </div>
            <div className="text-center pb-10 text-sm md:text-base w-hidden">
              <ReadMore limit={213} text={`We listened, we learned, and have designed our platform to reduce building management and concierge time and energies with real-time resident communications, maintenance tasks, parcel collection, facility bookings, access & security, asset management and car space management to name a few… And the benefit of our cloud base platform it can be accessed anytime, anywhere!`} />
            </div>
          </div>
          <div className="container mx-auto px-4 text-white md:mb-[180px]">
            <div className="overflow-x-auto md:overflow-x-visible md:max-w-[1080px] md:mx-auto">
              <div className="flex flex-nowrap md:grid md:grid-cols-4 gap-4 md:justify-center">
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="Manage-Facility-Bookings.png"
                    className={imageBackground}
                    src="../images/index/Manage-Facility-Bookings.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">Manage Facility Bookings</h2>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="Asset-Management.png"
                    className={imageBackground}
                    src="../images/index/Asset-Management.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">Asset Management</h2>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="Activities-Management.png"
                    className={imageBackground}
                    src="../images/index/Activities-Management.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">Activities Management</h2>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="Contractors.png"
                    className={imageBackground}
                    src="../images/index/Contractors.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">Contractors Sign-In / Out</h2>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="News.png"
                    className={imageBackground}
                    src="../images/index/News.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">News & Announcements</h2>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="Facility-Bookings-1.png"
                    className={imageBackground}
                    src="../images/index/Facility-Bookings-1.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">Manage Resident Database</h2>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="Marketplace-1.png"
                    className={imageBackground}
                    src="../images/index/Marketplace-1.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">Reporting & Analysis</h2>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="In-Home-Services-1.png"
                    className={imageBackground}
                    src="../images/index/In-Home-Services-1.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">Access Controls</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="section-relative" ref={socialRef}>
        <section className={section2}>
          {/*<div className="h-[375px] 2xl:h-[450px] section-3-image-background" />*/}
          <div className="w-hidden">
            <StaticImage
              alt="drop-off-collect.png"
              className=""
              src="../images/index/social-media-entertainment-mb.png"
            />
          </div>
          <div className="mb-hidden">
            <StaticImage
              alt="drop-off-collect.png"
              className="h-[406px] 2xl:h-full"
              src="../images/index/Web_19_Community + Social.png"
            />
          </div>
          <div className="absolute inset-0 m-auto overflow-auto w-full h-fit">
            <div className="overflow-y-hidden">
              <p className="font-bold text-[30px] leading-[30px] md:text-[90px] md:leading-[90px] justify-center text-center uppercase pink-linear head-text">Community + Social.</p>
            </div>
          </div>
        </section>
        <section className="my-20 md:my-0 md:px-[80px] xl:px-[180px] 2xl:px-auto">
          <div className="container mx-auto max-w-[1080px]">
            <div className="flex flex-col md:flex-row text-white">
              <div className="flex-1 md:my-auto">
                <div className="w-auto mx-[16px] md:mx-9 my-9 md:ml-9 md:mr-0 text-center md:text-left">
                  <p className="font-bold text-[16px] md:text-[20px] md:leading-[24px] bold-text pb-[40px] ">WE Wumbo transforms properties into communities, and residents into neighbours. </p>
                  <div>
                  <span className="text-sm md:text-base">
Our world is in a profound state of contradiction – increasingly connected, yet also fragmented. Statistics shows one in four Australians are lonely, which leads to depression, sickness and addiction… <br/>
WE Wumbo encourages social connection by providing a technology platform to foster sense of belonging, social groups, common interests, support and finding community leaders to create a future in which people enjoy kinder, happier and healthier lives.
                  </span>
                  </div>
                </div>
              </div>
              <div className="flex-1 md:order-first">
                <div className="w-auto mx-9 my-9 md:mx-auto md:mt-[-60px] xl:mt-[-120px]">
                  <StaticImage
                    alt="Image_WE-Hub.png"
                    className={imageBackground}
                    src="../images/index/Web_20_Community + Social.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="section-relative">
        <section className="py-2 md:py-40 text-white md:px-[80px] xl:px-[180px] 2xl:px-auto">
          <div className="flex justify-center md:mt-20">
            <p className="font-bold text-[30px] leading-[30px] md:text-[90px] md:leading-[90px] justify-center text-center uppercase blue-linear head-text">Social Groups.</p>
          </div>
          <div className="container mx-auto mt-10 px-4">
            <div className="overflow-x-auto md:overflow-x-visible md:max-w-[1080px] md:mx-auto">
              <div className="flex flex-nowrap md:grid md:grid-cols-4 gap-4 md:justify-center">
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="Fitness-Group.png"
                    className={imageBackground}
                    src="../images/index/Fitness-Group.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">Fitness Group</h2>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="Movie-Group.png"
                    className={imageBackground}
                    src="../images/index/Movie-Group.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">Movie Group</h2>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="Wine-Group.png"
                    className={imageBackground}
                    src="../images/index/Wine-Group.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">Wine Group</h2>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <StaticImage
                    alt="Pet-Group.png"
                    className={imageBackground}
                    src="../images/index/Pet-Group.png"
                  />
                  <h2 className="py-5 uppercase text-sm md:text-base head-text">Pet Group</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="text-white">
          <div className="flex justify-center mt-20 md:mt-0">
            <p className="font-bold text-[30px] leading-[30px] md:text-[90px] md:leading-[90px] justify-center text-center uppercase head-text">Our Communities.</p>
          </div>
          <div className="container mx-auto text-white">
            <div className="text-center pt-[14px] md:pt-10 pb-10 md:pb-20 px-4 text-sm md:text-base max-w-[1080px] mx-auto">
              Many Cultures, Many Ages & Many Backgrounds. Our communities boast some of the most prestigious addresses in Australia; from ultra luxury boutique apartment buildings to 1000+ apartment super towers and complexes.
            </div>
          </div>
          <div className="pb-0 md:pb-20 px-4 md:px-0 text-white">
            <div className="overflow-x-auto md:overflow-x-hidden">
              <div className="flex flex-nowrap gap-4 md:justify-center">
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <div className="md:w-[252px] 2xl:w-[310px]">
                    <StaticImage
                      alt="drop-off-collect.png"
                      className={imageBackground}
                      src="../images/index/com/Web_33_Our Communities.png"
                    />
                    <h2 className="py-4 uppercase text-sm md:text-base head-text">35 spring street</h2>
                  </div>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <div className="md:w-[252px] 2xl:w-[310px]">
                    <StaticImage
                      alt="drop-off-collect.png"
                      className={imageBackground}
                      src="../images/index/com/Web_34_Our Communities.png"
                    />
                    <h2 className="py-4 uppercase text-sm md:text-base head-text">Australia 108</h2>
                  </div>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <div className="md:w-[252px] 2xl:w-[310px]">
                    <StaticImage
                      alt="my-documents.png"
                      className={imageBackground}
                      src="../images/index/com/Web_35_Our Communities.png"
                    />
                    <h2 className="py-4 uppercase text-sm md:text-base head-text">Melbourne Square</h2>
                  </div>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <div className="md:w-[252px] 2xl:w-[310px]">
                    <StaticImage
                      alt="report-requests.png"
                      className={imageBackground}
                      src="../images/index/com/Web_36_Our Communities.png"
                    />
                    <h2 className="py-4 uppercase text-sm md:text-base head-text">Avant</h2>
                  </div>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <div className="md:w-[252px] 2xl:w-[310px]">
                    <StaticImage
                      alt="defect-reports.png"
                      className={imageBackground}
                      src="../images/index/com/Web_37_Our Communities.png"
                    />
                    <h2 className="py-4 uppercase text-sm md:text-base head-text">aspire</h2>
                  </div>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <div className="md:w-[252px] 2xl:w-[310px]">
                    <StaticImage
                      alt="defect-reports.png"
                      className={imageBackground}
                      src="../images/index/com/Web_38_Our Communities.png"
                    />
                    <h2 className="py-4 uppercase text-sm md:text-base head-text">Upper West Side</h2>
                  </div>
                </div>
              </div>
              <br/>
              <div className="flex flex-nowrap gap-4 md:justify-center">
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <div className="md:w-[252px] 2xl:w-[310px]">
                    <StaticImage
                      alt="what's-on.png"
                      className={imageBackground}
                      src="../images/index/com/Web_39_Our Communities.png"
                    />
                    <h2 className="py-4 uppercase text-sm md:text-base head-text">the harrington</h2>
                  </div>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <div className="md:w-[252px] 2xl:w-[310px]">
                    <StaticImage
                      alt="what's-on.png"
                      className={imageBackground}
                      src="../images/index/com/Web_40_Our Communities.png"
                    />
                    <h2 className="py-4 uppercase text-sm md:text-base head-text">PM port Melbourne</h2>
                  </div>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <div className="md:w-[252px] 2xl:w-[310px]">
                    <StaticImage
                      alt="facility-bookings.png"
                      className={imageBackground}
                      src="../images/index/com/Web_41_Our Communities.png"
                    />
                    <h2 className="py-4 uppercase text-sm md:text-base head-text">west side place</h2>
                  </div>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <div className="md:w-[252px] 2xl:w-[310px]">
                    <StaticImage
                      alt="marketplace.png"
                      className={imageBackground}
                      src="../images/index/com/Web_42_Our Communities.png"
                    />
                    <h2 className="py-4 uppercase text-sm md:text-base head-text">aurora melbourne</h2>
                  </div>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <div className="md:w-[252px] 2xl:w-[310px]">
                    <StaticImage
                      alt="in-home-services.png"
                      className={imageBackground}
                      src="../images/index/com/Web_43_Our Communities.png"
                    />
                    <h2 className="py-4 uppercase text-sm md:text-base head-text">sky garden</h2>
                  </div>
                </div>
                <div className="w-2/5 md:w-fit flex-none text-center">
                  <div className="md:w-[252px] 2xl:w-[310px]">
                    <StaticImage
                      alt="in-home-services.png"
                      className={imageBackground}
                      src="../images/index/com/Web_44_Our Communities.png"
                    />
                    <h2 className="py-4 uppercase text-sm md:text-base head-text">NV Apartments</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={section2}>
          {/*<div className="h-[375px] 2xl:h-[450px] section-3-image-background" />*/}
          <div className="w-hidden">
            <StaticImage
                alt="drop-off-collect.png"
                className=""
                src="../images/index/Image_Build to Rent_Mobile.png"
            />
          </div>
          <div className="mb-hidden">
            <StaticImage
                alt="drop-off-collect.png"
                className="h-[406px] 2xl:h-full"
                src="../images/index/Image_Build to Rent.png"
            />
          </div>
          <div className="absolute inset-0 m-auto overflow-auto w-full h-fit">
            <div className="overflow-y-hidden">
              <p className="font-bold text-[30px] leading-[30px] md:text-[90px] md:leading-[90px] justify-center text-center uppercase text-white head-text">Build to Rent. </p>
            </div>
          </div>
        </section>
        <section className={section3}>
          <div className="py-[40px] md:py-[80px] pd:my-0 max-w-[1080px] mx-auto">
            <span className="text-sm md:text-base">
              Build to Rent (BTR) has proven to be a global success particularly in the United States since the 1980s (known as multi-family housing) and in United Kingdom since 2010, as an established asset class. And with the present housing affordability challenges, the ‘Australian Dream’ of home ownership has become progressively difficult, the model is beginning to garner more and more interest in Australia.
              <br/> <br/>
              Property Developers and alike are embracing the relatively new asset class which is shown in the chart below by the number of apartments set to be released to residents over the coming years.
            </span>
          </div>
        </section>
        <section className="container mx-auto text-white">
          <div className="text-center pt-[40px] md:pt-0 px-4 text-sm md:text-base max-w-[1080px] mx-auto">
            <div className="pb-10">
              <p className="text-[16px] leading-[16px] md:text-[32px] md:leading-[32px] head-text">Developers by Number of Units & Asset Value</p>
            </div>
            <div className="w-hidden mx-auto">
              <StaticImage
                alt="drop-off-collect.png"
                className=""
                src="../images/index/Developers by Number of Units & Asset Value 2.png"
              />
            </div>
            <div className="mb-hidden mx-auto max-w-[800px] h-auto">
              <StaticImage
                alt="drop-off-collect.png"
                className=""
                src="../images/index/Developers by Number of Units & Asset Value 2.png"
              />
            </div>
          </div>
        </section>
        <section className={section3}>
          <div className="mb-[40px] mt-[80px] md:my-[80px] text-sm md:text-base max-w-[1080px] mx-auto">
            <span className="text-sm md:text-base">
              As BTR is becoming more prevalent across the property landscape we have understood the need to provide technology enhancements to residents and operational teams to assist in fostering community, operational competences and discover the convenience of vertical living. We call it the convergence of the human, physical and digital innovations inside and outside the home.
              <br/> <br/>
              Technology is the key enabler for BTR in broad terms three categories;
              <br/>
              <strong>Resident Experience, Building Operations & Management and Marketing & Lease Management</strong>
            </span>
          </div>
        </section>
        <section className="container mx-auto text-white">
          <div className="text-center px-10 text-sm md:text-base max-w-[1080px] mx-auto">
            <img className="mx-auto w-full md:max-w-[540px] h-auto" src={buildingGif} alt="Otter dancing with a fish" />
          </div>
        </section>
        <section className={section3}>
          <div className="py-[40px] md:py-[80px] text-sm md:text-base max-w-[1080px] mx-auto">
            <span className="text-sm md:text-base">
              The success of the BTR model will evolve around its ability to differentiate from the private rental sector through the level of service (management), security, design, amenity, tenure and lifestyle offerings. The ability to adopt the mentality of ‘customer’ rather than ‘tenants’ for Developers will be imperative.
            </span>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <Seo title="Home" />
)

export default IndexPage
