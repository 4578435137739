import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import ReadMore from "../../components/read-more"
import { useEffect, useState } from "react"

const NewsPage = ({ data, location }) => {
  const [width, setWidth] = useState(0)
  useEffect(() => {
    const onScroll = () => {
      setWidth(window.innerWidth)
    }
    window.removeEventListener("scroll", onScroll)
    window.addEventListener("scroll", onScroll, { passive: true })
    return () => window.removeEventListener("scroll", onScroll)
  }, [])
  return (
    <Layout pageTitle="News" location={location}>
      <h1 className="my-4 md:mt-0 md:mb-20 md:pt-20 text-center text-[30px] md:text-[90px] leading-none uppercase pink-linear py-0 head-text uppercase blue-linear">news & media.</h1>
      <section className="mx-auto md:mb-[80px] pb-[40px] md:pb-[80px] md:px-[80px] xl:px-[180px] 2xl:px-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-4 md:max-w-[1080px] md:mx-auto">
          {
            data.allMdx.nodes.map(node => (
              <div className="col-span-1" key={node.id}>
                <div className="px-4 md:px-1 md:mt-10 text-white">
                  <div className="flex flex-col">
                    <div className="order-first md:order-last pt-5">
                      <h1 className="text-[24px] md:text-[32px] leading-[24px] md:leading-[32px] uppercase head-text">{node.frontmatter.title}</h1>
                      <p className="text-base text-[14px] md:text-[16px] leading-[14px] md:leading-[16px] head-text uppercase pt-[5px] md:pt-[10px] pb-4 md:pb-0">{node.frontmatter.date}</p>
                    </div>
                    <div className="mb-hidden">
                      <Link className="text-blue" to={`/news/${node.frontmatter.slug}`}>
                        <GatsbyImage
                          imgStyle={{ borderRadius: '4px' }}
                          image={getImage(node.frontmatter.hero_image)}
                          alt={node.frontmatter.hero_image_alt}
                        />
                      </Link>
                    </div>
                    <div className="w-hidden">
                      <GatsbyImage
                        imgStyle={{ borderRadius: '4px' }}
                        image={getImage(node.frontmatter.hero_image_mb)}
                        alt={node.frontmatter.hero_image_alt}
                      />
                    </div>
                  </div>
                  <div className="pt-4 w-hidden">
                    <div className="text-base normal-text text-[14px] line-[17px] md:text-[16px] md:leading-[19px]">
                      <ReadMore limit={236} text={node.body} />
                    </div>
                  </div>
                  <div className="pt-[20px] mb-hidden">
                    <p className="text-base normal-text text-[14px] line-[17px] md:text-[16px] md:leading-[19px]">
                      {node.excerpt}
                    </p>
                  </div>
                  <div className="pt-5 mb-hidden">
                    <Link className="text-blue" to={`/news/${node.frontmatter.slug}`}>Read More</Link>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        id
        excerpt(pruneLength: 251)
        body
        frontmatter {
          date(formatString: "DD MMM YYYY")
          title
          slug
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
          hero_image_mb {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export const Head = () => <Seo title="News" />

export default NewsPage
