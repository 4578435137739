/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "WE Wumbo is excited to announce our first \"live\" building in Box Hill."), "\n", React.createElement(_components.p, null, "Residing at the heart of Box Hill, Prospect (194 apartments), is a residential community thoughtfully created by R&F Property Australia, presents a new residential benchmark in Box Hill. Combined with five-star amenities including a porte cohere, pool, gym, indoor / outdoor entertainment spaces and concierge service to deliver hotel-style living experience."), "\n", React.createElement(_components.p, null, "By design, WE Wumbo App is the perfect digital platform to deliver R&F Property’s vision and legacy with Prospect. We look forward to working with the facilities management team, Connect Facilities to provide enriched resident & community experiences and operational efficiencies."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
