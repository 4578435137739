import GATSBY_COMPILED_MDX from "/Users/cong/we_projects/wewumbo.io_v3/news/our-first-live-building-in-box-hill/index.mdx";
import * as React from 'react';
import {graphql, Link} from "gatsby";
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import LayoutDetail from '../../components/layout-detail';
import Seo from '../../components/seo';
const NewsPost = ({data, location}) => {
  const image = getImage(data.mdx.frontmatter.hero_image_detail);
  return React.createElement(LayoutDetail, {
    pageTitle: data.mdx.frontmatter.title,
    location: location
  }, React.createElement("div", {
    className: "mx-auto"
  }, React.createElement("div", {
    className: "px-4 md:mt-10 text-black md:px-[80px] xl:px-[180px] 2xl:px-auto"
  }, React.createElement("div", {
    className: "md:max-w-[1080px] md:mx-auto"
  }, React.createElement("div", {
    className: "md:pt-10"
  }, React.createElement(Link, {
    to: "/news",
    className: "flex content-center cursor-pointer"
  }, React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: "1.5",
    stroke: "currentColor",
    className: "w-6 h-6 text-brown"
  }, React.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "M15.75 19.5L8.25 12l7.5-7.5"
  })), React.createElement("div", {
    className: "px-2 text-base md:text-[20px] align-middle"
  }, "Back"))), React.createElement("div", {
    className: "flex flex-col"
  }, React.createElement("div", {
    className: "order-first pt-5"
  }, React.createElement("h1", {
    className: "text-[24px] md:text-[32px] leading-[24px] md:leading-[32px] font-bold uppercase head-text"
  }, data.mdx.frontmatter.title), React.createElement("p", {
    className: "text-[14px] md:text-[16px] head-text uppercase pt-[5px] md:pt-[10px]"
  }, data.mdx.frontmatter.date)), React.createElement("div", {
    className: "pt-4 md:pt-10"
  }, React.createElement(GatsbyImage, {
    imgStyle: {
      borderRadius: '4px'
    },
    image: image,
    alt: data.mdx.frontmatter.hero_image_alt
  }))), React.createElement("div", {
    className: "pt-10 pb-10 md:pb-20"
  }, React.createElement("div", {
    className: "text-base text-black whitespace-pre-line"
  }, data.mdx.body))))));
};
export const query = graphql`
  query($id: String) {
    mdx(id: {eq: $id}) {
      excerpt
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        hero_image_alt
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        hero_image_mb {
          childImageSharp {
            gatsbyImageData
          }
        }
        hero_image_detail {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
export const Head = ({data}) => React.createElement(Seo, {
  title: data.mdx.frontmatter.title
});
NewsPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(NewsPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
