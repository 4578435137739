/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "We are excited to announce the launch of our new Wumbo Rewards!"), "\n", React.createElement(_components.p, null, "Our Wumbo Rewards is now live where we've partnered with one of Australia’s largest Loyalty Programs My Rewards."), "\n", React.createElement(_components.p, null, "Average Australian families are currently facing economic challenges such as the rise in cost of living, affordability, and increased interest rates making it difficult to access daily essential items. Hence, why we have partnered with My Rewards who are one of Australia’s leading loyalty programs with over 4.6million members to create our Wumbo Rewards program and assist our communities ease these ongoing stress, with more than 4,500 National and International Retailers offering discounts and cashbacks across a range of products and services. Melbourne"), "\n", React.createElement(_components.p, null, "By joining our rewards program, WE Wumbo residents will be able to unlock yearly savings of more than $2,000 for single household and up to $6,000 for families! This is the first of many initiatives that we plan to activate in 2023 to enrich the lives of our community."), "\n", React.createElement(_components.p, null, "WE Wumbo is a lifestyle, a community, it’s the future of connected living, today."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
