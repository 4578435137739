import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import favicon from "../images/favicon.png"

const Seo = ({ title, description, pathname, children }) => {
  const { title: defaultTitle, description: defaultDescription, siteUrl, twitterUsername } = useSiteMetadata()
  // console.log(useSiteMetadata())

  // const seo = {
  //   title: title || defaultTitle,
  //   description: description || defaultDescription,
  //   url: `${siteUrl}${pathname || ``}`,
  //   twitterUsername,
  // }

  return (
    <React.Fragment>
      <title>WE Platform by WUMBO</title>
      <meta name="author" content="WUMBO" />
      <meta name="description" content="WE is a resident facing, integrated Building Management platform that redefines the way buildings are managed and operated. WE shifts the focus from assets to people." />
      <meta name="image" content="https://s3-ap-southeast-2.amazonaws.com/general.upload.for.app/wewumbo_web/main.png" />
      {/*<meta name="twitter:card" content="summary_large_image" />*/}
      <meta name="generator" content="WUMBO" />
      <meta name="twitter:title" content="WUMBO" />
      <meta name="twitter:url" content="https://www.wewumbo.io" />
      <meta name="twitter:description" content="WE is a resident facing, integrated Building Management platform that redefines the way buildings are managed and operated. WE shifts the focus from assets to people." />
      <meta name="twitter:image" content="https://s3-ap-southeast-2.amazonaws.com/general.upload.for.app/wewumbo_web/main.png" />
      <meta name="twitter:creator" content="WUMBO" />
      <link id="icon" rel="icon" href={favicon} />
      {children}
    </React.Fragment>
  )
}

export default Seo
