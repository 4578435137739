/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Melbourne Square hosted their first Christmas Party for stakeholders and residents. It was fantastic to see so many Melbourne Square residents who come from many cultures, age groups and backgrounds getting together to celebrate this year’s achievements and connect over food and drinks. We look forward to celebrating many more future community events with Essential Community Management at Melbourne Square and help foster more community engagement through the WE Wumbo App!");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
