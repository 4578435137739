exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-index-js": () => import("./../../../src/pages/company/index.js" /* webpackChunkName: "component---src-pages-company-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-users-cong-we-projects-wewumbo-io-v-3-news-christmas-at-melbourne-square-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/cong/we_projects/wewumbo.io_v3/news/christmas-at-melbourne-square/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-users-cong-we-projects-wewumbo-io-v-3-news-christmas-at-melbourne-square-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-users-cong-we-projects-wewumbo-io-v-3-news-our-first-live-building-in-box-hill-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/cong/we_projects/wewumbo.io_v3/news/our-first-live-building-in-box-hill/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-users-cong-we-projects-wewumbo-io-v-3-news-our-first-live-building-in-box-hill-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-users-cong-we-projects-wewumbo-io-v-3-news-upper-west-sides-quiz-night-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/cong/we_projects/wewumbo.io_v3/news/upper-west-sides-quiz-night/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-users-cong-we-projects-wewumbo-io-v-3-news-upper-west-sides-quiz-night-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-users-cong-we-projects-wewumbo-io-v-3-news-we-never-stop-bring-on-2023-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/cong/we_projects/wewumbo.io_v3/news/we-never-stop-bring-on-2023/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-users-cong-we-projects-wewumbo-io-v-3-news-we-never-stop-bring-on-2023-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-users-cong-we-projects-wewumbo-io-v-3-news-wewumbo-gets-digital-with-effi-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/cong/we_projects/wewumbo.io_v3/news/wewumbo-gets-digital-with-effi/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-users-cong-we-projects-wewumbo-io-v-3-news-wewumbo-gets-digital-with-effi-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-users-cong-we-projects-wewumbo-io-v-3-news-wumbo-rewards-is-now-live-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/cong/we_projects/wewumbo.io_v3/news/wumbo-rewards-is-now-live/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-users-cong-we-projects-wewumbo-io-v-3-news-wumbo-rewards-is-now-live-index-mdx" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-reward-index-js": () => import("./../../../src/pages/reward/index.js" /* webpackChunkName: "component---src-pages-reward-index-js" */),
  "component---src-pages-smart-building-index-js": () => import("./../../../src/pages/smart-building/index.js" /* webpackChunkName: "component---src-pages-smart-building-index-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */)
}

